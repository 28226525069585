import { Tooltip } from 'reactstrap';
import styled from 'styled-components';

export const SelectsArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 16px;
`

export const SelectArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 49%;
`

export const TableArea = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const TableWrapper = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;



export const TableStyled = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 8px;

  table {
    width: 100%;
    border-spacing: 0px 1px;
    border-collapse: separate;
    background-color: #f4f4f4;

    thead {
      position: sticky; 
      top: 0;
      z-index: 1;
      background-color: #f4f4f4;

      tr {
        display: table; 
        width: 100%;
        table-layout: fixed;

        th {
          text-align: center;
          font-weight: bold;
          padding: 16px;
        }
      }
    }

    tbody {
      display: block;
      max-height: 300px; 
      overflow-y: auto; 

      tr {
        display: table;
        table-layout: fixed; 
        width: 100%;

        td {
          text-align: center;
          background-color: #fff;
          padding: 16px;

          &:first-child {
            border-left: 1px solid #ededed;
          }

          &:last-child {
            border-right: 1px solid #ededed;
          }
        }
      }

      tr:last-child td:first-child {
        border-bottom-left-radius: 8px;
      }

      tr:last-child td:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }

  tbody::-webkit-scrollbar {
    width: 7px; /* Define a largura da scrollbar */
  }
`;

export const CustomTooltip = styled(Tooltip)`
  & .tooltip-inner {
    background-color: #222222 !important; 
    color: white !important; 
  }

  & .tooltip-arrow::before {
    border-bottom-color: #222222 !important; 
  }
`;