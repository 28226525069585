import { Modal, Tooltip } from 'reactstrap';
import styled from 'styled-components';
import { Button } from 'takeat-design-system-ui-kit';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #C8131B ;
  margin-bottom: 10px;
  padding: 16px;
  box-shadow: 0px 2px 4px 0px #00000024;
  animation: height 2s ease-in-out;

`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const ProductInfoArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabelArea = styled.div``;

export const ProductInfo = styled.div`
 display: flex;
  flex-direction: column;
`;

export const ProductName = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #000;
`;

export const CategoryName = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #545454;
`;

export const TableStyled = styled.table`
  width: 100%;
  border-spacing: 10px 5px;
  border-collapse: separate; /* Permite usar border-spacing */
  background-color: #fff;

  thead {
    tr {
      
    }

    th {
      text-align: center;
      font-weight: bold;
    }
  }

  tbody {
    td {
      text-align: center;
    }
  }
`;

export const InputTableStyled = styled.table`
  width: 100%;
  border-spacing: 0px 1px; /* Espaço entre linhas */
  border-collapse: separate; /* Permite usar border-spacing */
  background-color: #f4f4f4;
  border-radius: 8px;
  overflow: hidden; /* Garante que os cantos arredondados sejam visíveis */

  thead {
    tr {
      th {
        text-align: center;
        font-weight: bold;
        padding: 16px;

        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        text-align: center;
        background-color: #fff;
        padding: 16px;

        &:first-child {
          border-left: 1px solid #ededed;
          text-align: left;
        }

        &:last-child {
          border-right: 1px solid #ededed; 
          text-align: right;
        }
      }
    }
  }

  tbody tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }

  tbody tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
`;

export const ResultButton = styled(Button)`
  height: 32px;
  border-radius: 8px;
  width: 128px;

  @media (max-width: 1025px){
    width: 92px;
  }
`;

export const StockArea = styled.div`
  margin-top: 16px;
  max-height: ${(props) => (props.show ? "auto" : "0")}; 
  opacity: ${(props) => (props.show ? "1" : "0")};
  overflow: hidden; 
  transition: opacity 0.7s ease; 
`;

export const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 8px; /* Exemplo: Personaliza o conteúdo */
    width: 110%;
  }
`;

export const InputsArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`
export const CustomTooltip = styled(Tooltip)`
  & .tooltip-inner {
    background-color: #222222 !important; 
    color: #fff !important; 
    width: 341px !important;
    max-width: none !important; /* Remove limite do Bootstrap */
  }

  & .tooltip-arrow::before {
    border-bottom-color: #222222 !important; 
  }
`;