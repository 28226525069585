import React, { useState } from 'react';
import { Container, Modal, ModalBody } from 'reactstrap';
import {
  Pedido,
  OrderNumber,
  Time,
  ContentBody,
  TableNumber,
  PhoneNumber,
  DeleteAndEditActions,
  CardColumnItem,
  CardRow,
} from '../styles';

import formatTime from '~/services/formatTime';
import formatPhone from '~/services/formatPhoneString';

import EyeModalOrders from '~/components/EyeModalOrders';
import { OrderButton, OrderStatusTag } from 'ui-kit-takeat';
import { FaEye } from 'react-icons/fa';

export default function OrderCanceled({ item }) {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const userName = item?.buyer
    ? formatPhone(
        item?.buyer?.phone ? item?.buyer.phone : item?.buyer?.ifood_phone
      ) || item?.buyer.name
    : item?.waiter?.name;


  function searchTableName(table) {
    if (table?.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table?.table_type === 'pos') {
      return 'POS';
    }

    if (table?.table_type === 'self-service') {
      return 'Totem';
    }

    if (table?.table_number === -10) {
      return 'Mesa Copa';
    }

    if (table?.table_type === 'prepaid') {
      return `Mesa  ${table.table_number} - Pagamento Antecipado`;
    }

    if (table?.table_type === 'delivery') {
      if (item?.with_withdrawal && item?.scheduled_to) {
        return 'Retirada Agendada';
      }
      if (item?.with_withdrawal) {
        return 'Retirada';
      }

      if (!item?.with_withdrawal && item?.scheduled_to) {
        return 'Delivery Agendado';
      }

      return 'Delivery';
    }

    return `Mesa  ${table?.table_number}`;
  }

  return (
    <Pedido>
      <ContentBody>
        <CardRow>
          <CardColumnItem style={{ marginTop: 5, marginLeft: 2 }} onStart>
            <TableNumber>
              {item?.table?.table_type === 'command'
                ? `Comanda  ${item?.table.table_number} / Mesa ${
                    item?.basket?.command_table_number || 'não informada'
                  } `
                : searchTableName(item?.table)}
            </TableNumber>
          </CardColumnItem>
          <CardColumnItem>
            <OrderNumber>#{item?.basket?.basket_id}</OrderNumber>
          </CardColumnItem>
        </CardRow>

        <CardRow>
          <CardColumnItem style={{ marginLeft: 2 }} onStart>
            <PhoneNumber>{userName}</PhoneNumber>
          </CardColumnItem>

          <CardColumnItem>
            {item && <Time>{formatTime(item?.basket?.start_time)}</Time>}
          </CardColumnItem>
        </CardRow>

        <CardRow>
          <div>
            <OrderStatusTag text="Cancelado" color="#A1A1A1" width="147px" />
          </div>
          <DeleteAndEditActions>
            <OrderButton icon={<FaEye />} color="#FFA814" onClick={toggle} />
          </DeleteAndEditActions>
        </CardRow>
      </ContentBody>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            <EyeModalOrders item={item} />
          </Container>
        </ModalBody>
      </Modal>
    </Pedido>
  );
}
