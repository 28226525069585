import React, { useMemo } from 'react';

import {
  Container,
  Table,
  Bill,
  Restaurant,
  Body,
  Phone,
  Items,
  Item,
  ItemName,
  Footer,
  PriceItem,
  NamePrice,
  TotalDiv,
  TotalRow,
  TotalName,
  TotalPrice,
  Complements,
  PriceComplement,
} from './styles';

import formatCompleteDate from '~/services/formatCompleteDate';
import formatPhone from '~/services/formatPhoneString';

const PrintBillsIndividual = React.forwardRef(({ item, restaurant }, ref) => {
  // const userName = useMemo(() => {
  //   return item?.bill.buyer
  //     ? formatPhone(item?.bill.buyer.phone)
  //     : item?.bill.waiter.name;
  // }, [item]);
  const userName = item?.bill?.buyer
    ? formatPhone(item.bill.buyer?.phone) || item.bill.buyer?.name
    : item.bill.waiter?.name;

  const date = useMemo(() => {
    const dateNow = new Date();
    return formatCompleteDate(dateNow);
  }, []);

  function changeValue(value) {
    const newValue = value.replace('.', ',');

    return newValue;
  }

  function searchTableName(item) {
    if (!item) {
      return 'Não informado';
    }

    const { table, bill } = item;

    if (table?.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table?.table_type === 'pos') {
      return 'POS';
    }

    if (table?.table_type === 'self-service') {
      return 'Totem';
    }

    if (table?.table_type === 'delivery') {
      return 'Delivery';
    }

    if (table?.table_number === -10) {
      return 'Mesa Copa';
    }

    if (table?.table_type === 'command') {
      const command = bill?.command_table_number || 'não informada';

      return `Comanda ${table?.table_number} / Mesa ${command}`;
    }

    return `Mesa ${table?.table_number}`;
  }

  return (
    <Container ref={ref}>
      <Table>
        <strong>{searchTableName(item)}</strong>
      </Table>
      <Bill>
        <strong>Comanda Individual</strong>
      </Bill>
      <Bill>
        <strong>Fechada {date}</strong>
      </Bill>
      <Restaurant>
        <strong>{restaurant?.fantasy_name}</strong>
      </Restaurant>
      <Body>
        <Phone>
          <strong>{userName}</strong>
        </Phone>
      </Body>
      <Items>
        {item?.bill.order_baskets.map((basket) => (
          <React.Fragment key={basket.id}>
            {basket.orders.map((order) => (
              <Item key={order.id}>
                <NamePrice>
                  <ItemName>
                    {order.use_weight ? (
                      <strong>
                        {changeValue(order.weight)}kg {order.product.name}
                      </strong>
                    ) : (
                      <strong>
                        {order.amount}x {order.product.name}
                      </strong>
                    )}
                  </ItemName>
                  <PriceItem>
                    <strong>R${order.total_price}</strong>
                  </PriceItem>
                </NamePrice>
                {order.complement_categories?.map((category) => (
                  <React.Fragment key={category.id}>
                    {category.order_complements.map((complement) => (
                      <div key={complement.id}>
                        <NamePrice>
                          <Complements>
                            <strong>
                              - {complement.amount}x{' '}
                              {complement.complement.name}{' '}
                            </strong>
                          </Complements>
                          <PriceComplement>
                            <strong>R${complement.complement.price}</strong>
                          </PriceComplement>
                        </NamePrice>
                      </div>
                    ))}
                  </React.Fragment>
                ))}
              </Item>
            ))}
          </React.Fragment>
        ))}

        <Item>
          <NamePrice>
            <ItemName>
              <strong>Serviços ({restaurant?.service_tax}% - Opcional)</strong>
            </ItemName>
            <PriceItem>
              <strong>
                R$
                {(
                  item?.bill.total_service_price - item?.bill.total_price
                ).toFixed(2)}
              </strong>
            </PriceItem>
          </NamePrice>
        </Item>
      </Items>
      <TotalDiv>
        {item?.bill?.rescues?.map((rescue) => (
          <TotalRow>
            <TotalName>
              <strong>Resgate clube</strong>
            </TotalName>
            <TotalPrice>
              <strong>
                - R$
                {parseFloat(rescue.cashback).toFixed(2)}
              </strong>
            </TotalPrice>
          </TotalRow>
        ))}
        <TotalRow>
          <TotalName>
            <strong>TOTAL</strong>
          </TotalName>
          <TotalPrice>
            <strong>
              R$
              {(
                item?.bill.total_service_price -
                item?.bill?.rescues?.reduce(
                  (acc, cur) => acc + parseFloat(cur.cashback),
                  0
                )
              ).toFixed(2)}
            </strong>
          </TotalPrice>
        </TotalRow>
      </TotalDiv>
      <Footer>
        <strong>Desenvolvido por Takeat</strong>
      </Footer>
    </Container>
  );
});

export default PrintBillsIndividual;