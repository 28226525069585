export const getTableName = (table) => {
  const { table_number, table_type } = table;

  switch (table_type) {
    case 'delivery':
      return 'Delivery';
    case 'balcony':
      return 'Balcão';
    case 'table':
      return table_number;
    default:
      return table_number;
  }
};

export const getTableNameByNumber = (table_number, table_type) => {
  console.log('tablety  ', table_type)
  switch (table_number) {
    case -1:
      return 'Balcão';
    case -3:
      return 'Delivery';
    case -4:
      return 'POS';
    case -5:
      return 'Totem';
    default:
      switch (table_type) {
        case 'command':
          return 'Comanda ' + table_number;
        default:
          return 'Mesa ' + table_number;
      }
  }
};

export const getStatusName = (status) => {
  switch (status) {
    case 'open':
      return 'Aberta';
    case 'finished':
      return 'Fechada';
    case 'completed':
      return 'Finalizada';
    case 'waiting_payment':
      return 'Aguardando pagamento';
    case 'closing':
      return 'Em fechamento';
    default:
      return status;
  }
};