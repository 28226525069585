import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './config/ReactotronConfig';
import { Router } from 'react-router-dom';
import light from './styles/theme/light';
import GlobalStyle from './styles/global';
import Routes from './routes';
import history from './services/history';
import { AuthProvider } from './context/AuthContext';
import { TablesProvider } from './context/TablesContext';
import { NotificationProvider } from './context/notificationContext';
import { OrderProvider } from './context/orderContext';
import { ClosingProvider } from './context/closingContext';
import { CartProvider } from './context/OrderingSystem/Cart';
import { OrderingSystemProvider } from './context/OrderingSystem/Order';
import { MenuProvider } from './context/OrderingSystem/Menu';
import { BillsProvider } from './context/BillsContext';
import { IFoodProvider } from './context/iFoodContext';
import { WaiterTicketProvider } from './context/WaiterTicketContext';
import { MotoboysProvider } from './context/MotoboysContext';
import { ClubeProvider } from './context/ClubeContext';
import { VideosProvider } from './context/VideosContext';

import { Toaster } from 'react-hot-toast';
import { PosProvider } from './context/PosContext';
import { UzapiProvider } from './context/UzapiContext';
import { WhatsgwProvider } from './context/WhatsgwContext';
import { PreCartProvider } from './context/PreCartContext';
import { DeliveryOpeningHourProvider } from './context/DeliveryOpeningHour';
import { DEFAULT_THEME, UiKitTheme } from 'takeat-design-system-ui-kit';

function App() {
  useEffect(() => {
    const test1 = document.getElementById('hubspot-messages-iframe-container');
    const test2 = document.getElementById('hubspot-conversations-iframe');
    console.log('iframe hubspot 1 > ', test1);
    console.log('iframe hubspot 2 > ', test2);
  }, []);

  const CUSTOM_THEME = {
    ...DEFAULT_THEME,
    colors: {
      ...DEFAULT_THEME.colors,
      secondary: '#ff403d',
      tertiary: '#90C2E7',
      confirm: '#7fd1b9',
      info: '#eac435',
      white: '#fff',
      gray: '#97928F',
      lightGray: '#ddd',

      background: '#F0F0EF',
      text: '#333',
    },
  };

  return (
    <UiKitTheme theme={CUSTOM_THEME}>
      <AuthProvider>
        <DeliveryOpeningHourProvider>
          <PosProvider>
            <VideosProvider>
              <BillsProvider>
                <TablesProvider>
                  <NotificationProvider>
                    <UzapiProvider>
                      <WhatsgwProvider>
                        <OrderProvider>
                          <IFoodProvider>
                            <ClosingProvider>
                              <DndProvider backend={HTML5Backend}>
                                <Router history={history}>
                                  <OrderingSystemProvider>
                                    <PreCartProvider>
                                      <CartProvider>
                                        <MenuProvider>
                                          <MotoboysProvider>
                                            <ClubeProvider>
                                              <WaiterTicketProvider>
                                                <GlobalStyle />
                                                <Routes />
                                                <Toaster
                                                  toastOptions={{
                                                    duration: 3000,
                                                    style: {
                                                      padding: '12px 10px',
                                                      boxShadow:
                                                        '0 1px 10px rgb(0 0 0 / 30%)',
                                                    },
                                                    success: {
                                                      style: {
                                                        border:
                                                          '2px solid #61d345',
                                                      },
                                                    },
                                                    error: {
                                                      style: {
                                                        border:
                                                          '2px solid #ff4b4b',
                                                      },
                                                    },
                                                  }}
                                                />
                                              </WaiterTicketProvider>
                                            </ClubeProvider>
                                          </MotoboysProvider>
                                        </MenuProvider>
                                      </CartProvider>
                                    </PreCartProvider>
                                  </OrderingSystemProvider>
                                </Router>
                              </DndProvider>
                            </ClosingProvider>
                          </IFoodProvider>
                        </OrderProvider>
                      </WhatsgwProvider>
                    </UzapiProvider>
                  </NotificationProvider>
                </TablesProvider>
              </BillsProvider>
            </VideosProvider>
          </PosProvider>
        </DeliveryOpeningHourProvider>
      </AuthProvider>
    </UiKitTheme>
  );
}

export default App;
