import { Modal, Tooltip } from 'reactstrap';
import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 60px);
  padding: 20px;
  overflow-y: scroll;
  padding-bottom: 100px;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
`
export const ActionArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`

export const SearchArea = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ButtonsArea = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Card = styled.div`
  margin-top: 10px;
  border-radius: 8px;
  background: #FFF;
  padding: 16px;
`

export const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 8px; /* Exemplo: Personaliza o conteúdo */
    width: 110%;
  }
`;

export const InputsArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const TableStyled = styled.table`
  width: 100%;
  border-spacing: 0px 1px; /* Espaço entre linhas */
  border-collapse: separate; /* Permite usar border-spacing */
  background-color: #f4f4f4;
  border-radius: 8px;
  overflow: hidden; /* Garante que os cantos arredondados sejam visíveis */

  thead {
    tr {
      th {
        text-align: center;
        font-weight: bold;
        padding: 16px;
      }
    }
  }

  tbody {
    tr {
      td {
        text-align: center;
        background-color: #fff;
        padding: 16px;

        &:first-child {
          border-left: 1px solid #ededed; 
        }

        &:last-child {
          border-right: 1px solid #ededed; 
        }
      }
    }
  }

  tbody tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }

  tbody tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
`;

export const CustomTooltip = styled(Tooltip)`
  & .tooltip-inner {
    background-color: #222222 !important; 
    color: #fff !important; 
    width: 341px !important;
    max-width: none !important; /* Remove limite do Bootstrap */
  }

  & .tooltip-arrow::before {
    border-bottom-color: #222222 !important; 
  }
`;

export const TableColorsStyled = styled.table`
  width: 100%;
  border-spacing: 2px 2px; /* Espaço entre linhas */
  border-collapse: separate; /* Permite usar border-spacing */
  border-radius: 8px;
  overflow: hidden; /* Garante que os cantos arredondados sejam visíveis */
  color: #000;
  margin-top: 8px;

  tbody{
    tr{
      td{
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        width: 141px;
        height: 32px;
      }
    }
  }
  `
//#BCE3DE
//#FFEBC8
//#E5A1A4

