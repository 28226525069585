/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  FaArrowLeft,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaPlus,
  FaPrint,
  FaTrash,
  FaUserCircle,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';
import InputMask from 'react-input-mask';
import ReactToPrint from 'react-to-print';
import { toast } from 'react-hot-toast';
import { format } from 'date-fns';

import {
  BillsItemsArea,
  Container,
  PartialBillArea,
  Header,
  BackButton,
  PrintButton,
  StyledTable,
  PerfilArea,
  ButtonAddBill,
  SelectStyled,
  DisplayNoneImprimir,
  PaymentArea,
  RescueContainer,
  RescueDate,
  RescueButton,
  Total,
  Paid,
  PaymentTable,
  DeletePaymentForm,
  BlueButton,
  PaymentMethodButton,
  AddPaymentForm,
  PaymentDiv,
  InformationDiv,
  ButtonsArea,
  InnerArea,
} from './styles';
import api from '~/services/api';
import apiClube from '~/services/apiClube';

import formatPhone from '~/services/formatPhoneString';
import formatValue from '~/services/formatValue';
import PrintBillsDivide from '~/components/Prints/PrintBillsDivide';
import { FiTrash, FiX } from 'react-icons/fi';
import Input from '~/components/Form/Input';
import { Form } from '@unform/web';
import { useAuth } from '~/context/AuthContext';
import {
  Spinner,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import InputPaymentForm from '~/components/Form/InputPaymentForm';
import Select from '~/components/Form/SelectInput';
import CheckboxInput from '~/components/Form/Checkbox';
import PersonalButton from '~/components/Buttons/PersonalButton';
import PrintBillsTableOperation from '~/components/Prints/PrintBillsTableOperation';
import { useClube } from '~/context/ClubeContext';
import { IconInput } from 'ui-kit-takeat';
import { CLUBE_METHOD } from '~/utils/consts';

function DivideBillsArea({
  printBills,
  billsState,
  sessionBills,
  productBills,
  commandTableNumber,
  count,
  setIsDivideBillsAreaOpen,
  printClick,
  session,
  setSession,
  setPaid,
  setCount,
  paymentsBill,
  setPaymentsBillFather,
  setAllPaymentsFather,
  handlePrintItem,
  getBillsSession,
  loadingBills,
  setAllTablesAvailable,
  setInputValue,
  setTablesFilteredSearch,
}) {
  // const { pendingBills } = useBills();
  const {
    toastUpdateSystemMessage3,
    setToastUpdateSystemMessage3,
    restaurantDiscountObs,
    user,
    paymentMethodsState,
    setData,
  } = useAuth();
  const {
    handlePontuarSession,
    sessionsAwarded,
    setRescues,
    rescues,
    saveSendedSession,
  } = useClube();
  const [divideByState, setDivideByState] = useState();
  const [billsDivideItems, setBillsDivideItems] = useState([]);
  const componentRef = useRef(null);
  const componentPrintRef = useRef(null);
  const [buttonsActive, setButtonsActive] = useState([]);

  //paymentsCashier
  // const payments_bill = session?.payments;
  const [payments, setAllPayments] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDrop = () => setDropdownOpen((prevState) => !prevState);
  const [metodo, setMetodo] = useState('Outros');
  const [applyDiscount, setApplyDiscount] = useState(false);
  const [discountObs, setDiscountObs] = useState(null);
  const [discountText, setDiscountText] = useState(false);
  const [discountObsOptions, setDiscountObsOptions] = useState([]);
  const [discount, setDiscount] = useState(null);
  const [discountPercent, setDiscountPercent] = useState(null);
  const [clubInfo, setClubInfo] = useState({
    phone: session.client?.phone,
    date: '',
  });
  const [clientCashback, setClientCashback] = useState({
    date: null,
    value: 0,
  });
  const [loadingCashback, setLoadingCashback] = useState(false);

  const [paymentOption, setPaymentOption] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [allPaymentMethods, setAllPaymentMethods] = useState([]);

  const [left, setLeft] = useState(0);

  const [favoriteMethods, setFavoriteMethods] = useState([]);

  function handleSetButtonsActive(id) {
    setButtonsActive((state) => {
      return [id];
    });
  }

  const getPaymentMethods = useCallback(async () => {
    const methods = paymentMethodsState.filter((method) => method.available);

    methods.sort((a, b) => Number(b.is_favorite) - Number(a.is_favorite));

    const parsedCompleteData = methods.map((data) => ({
      id: data.id,
      label: data.name,
      available: data.available,
      keyword: data.keyword,
    }));

    setAllPaymentMethods(parsedCompleteData);

    const favoriteMethodsSplice = methods.splice(0, 8);

    const parsedDataFav = favoriteMethodsSplice.map((data) => ({
      id: data.id,
      label: data.name,
      available: data.available,
      keyword: data.keyword,
    }));

    setFavoriteMethods(parsedDataFav);

    const parsedData = methods.map((data) => ({
      id: data.id,
      label: data.name,
      available: data.available,
      keyword: data.keyword,
    }));
    setPaymentMethods(parsedData);
  }, []);

  const addDiscountToSession = async (e) => {
    e.preventDefault();
    if (!discountObs || !discount) {
      toast.error('Selecione um valor e uma justificativa para o desconto');
    } else {
      try {
        const response = await api.put('/restaurants/table-session/discount', {
          discount,
          discountObs,
          userId: user.user_id,
          sessionId: session.id,
        });

        setApplyDiscount(false);
        setSession(response.data);
        toast.success('Desconto aplicado com sucesso!');
      } catch (err) {
        console.log('addDiscountToSession error: ', err);
        toast.error('Não foi possível aplicar o desconto');
      }
    }
  };

  // const [paymentsBill, setPaymentsBill] = useState(payments);

  const deletePayments = useCallback(
    async (id) => {
      if (window.confirm('Tem certeza que deseja remover esse pagamento?')) {
        await api.delete(`restaurants/payments/${id}`);

        const paymentFind = payments.filter((payment) => payment.id !== id);
        const paymentsNew = payments.filter((payment) => payment.id !== id);

        setAllPayments(paymentsNew);

        const newValue =
          paymentsNew.reduce((accum, curr) => {
            return accum + Number(curr.payment_value);
          }, 0) + paymentFind?.payment_value;

        setLeft(newValue);

        toast.success('Pagamento removido com sucesso!');
      }
    },
    [left]
  );

  const [value, setValue] = useState(0);

  const handleChangeDiscount = (target) => {
    const targetValue = target.value.replace(/(R|\$|%)/g, '');
    if (targetValue.length < 1) {
      setDiscount('');
      setDiscountPercent('');
    } else if (target.name === 'flat_discount') {
      setDiscount(targetValue);
      setDiscountPercent(
        (
          (parseFloat(targetValue.replace(',', '.')) /
            session?.total_service_price) *
          100
        ).toFixed(2)
      );
    } else {
      setDiscount(
        (
          (parseFloat(targetValue.replace(',', '.')) *
            session?.total_service_price) /
          100
        ).toFixed(2)
      );
      setDiscountPercent(parseFloat(targetValue));
    }
  };

  const [insertingPayment, setInsertingPayment] = useState(false);

  const newPayments = useCallback(async () => {
    try {
      if (paymentOption.id) {
        setInsertingPayment(true);
        const response = await api.post('restaurants/payments', {
          payment_value:
            paymentOption.id === CLUBE_METHOD
              ? clientCashback.value
              : Number(value),
          table_session_id: session.id,
          payment_method_id: paymentOption.id,
          rescues,
        });

        payments.push(response.data);

        setAllPayments(payments);

        if (response.data.clube) {
          saveSendedSession(response.data.clube);
        }

        setAllPaymentsFather((state) => {
          return [...state, response.data];
        });
        setPaymentsBillFather((state) => {
          return [...state, response.data];
        });

        paymentsBill.push(response.data);

        if (left > 0) {
          const newValueInsert =
            paymentOption.id === CLUBE_METHOD
              ? clientCashback.value
              : Number(value);

          setLeft(left - newValueInsert);
        } else {
          const newValueInsert =
            paymentOption.id === CLUBE_METHOD
              ? clientCashback.value
              : Number(value);

          const newValue =
            billsDivideItems.reduce((accum, curr) => {
              return accum + Number(curr.price);
            }, 0) - newValueInsert;

          setLeft(newValue);
        }

        const aleatoryId = v4();

        setCount(aleatoryId);

        setInsertingPayment(false);

        setClientCashback((state) => {
          return { ...state, value: 0 };
        });

        toast.success('Pagamento inserido com sucesso!');
      }
    } catch (error) {
      console.log(error.message);
      if (!error.response.ok) {
        if (error.response.data.errorType === 'cashier_opening_not_found') {
          toast.error(
            'Não foi encontrado caixa aberto. Favor abrir seu caixa.'
          );
        } else {
          setInsertingPayment(false);
          toast.error(
            error.response.data.message || 'Selecione o método de pagamento.'
          );
        }
      } else {
        console.log(error.message);
      }
    }
  }, [
    paymentOption.id,
    payments,
    setAllPayments,
    left,
    session,
    value,
    setCount,
    setAllPaymentsFather,
    setPaymentsBillFather,
    paymentsBill,
    rescues,
  ]);

  const getCashback = useCallback(async () => {
    try {
      let login_club;
      setLoadingCashback(true);
      if (!user.club_login) {
        login_club = await apiClube.post('/public/sessions/takeat', {
          token: user.token_clube,
        });

        localStorage.setItem(
          '@gddashboard:user',
          JSON.stringify({ ...user, club_login: login_club.data.token })
        );
      }
      setLoadingCashback(true);
      const response = await apiClube.get(`/store/cashback/${clubInfo.phone}`, {
        headers: {
          Authorization: `Bearer: ${user.club_login || login_club.data.token}`,
        },
      });

      setClientCashback(response.data);
    } catch (err) {
      if (err.response?.data?.errorType === 'default_error') {
        toast.error(err.response.data.message);
      } else {
        toast.error(
          'Não foi possível buscar o cashback referente a este telefone'
        );
      }
      console.log('getCashback error: ', err);
    }
    setLoadingCashback(false);
  }, [user.club_login, clubInfo.phone]);

  // useEffect(() => {
  //   try {
  //     getPayments();
  //   } catch (error) {
  //     toast.error('Erro ao carregar informações');
  //   }
  // }, [getPayments]);

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  useEffect(() => {
    if (!applyDiscount) {
      setDiscount(null);
      setDiscountObs(null);
      setDiscountPercent(null);
    }
  }, [applyDiscount]);

  useEffect(() => {
    newPayments();
  }, [paymentOption]);

  useEffect(() => {
    setValue(left.toFixed(2));
    setMetodo('Outros');
  }, [left]);

  useEffect(() => {
    if (clubInfo.phone?.length === 15) {
      getCashback();
      getClient(clubInfo.phone);
    }
  }, [clubInfo.phone]);

  useEffect(() => {
    if (
      clubInfo.date.length === 10 &&
      format(new Date(clientCashback.date), 'dd/MM/yyyy') !== clubInfo.date
    ) {
      toast.error('Data informada não coincide com a cadastrada');
    }
  }, [clubInfo.date]);

  async function setMetodoAndPaymentOption(payment) {
    setMetodo(payment.label);
    setPaymentOption({ id: payment.id, label: payment.label });

    if (payment.id === CLUBE_METHOD) {
      setRescues((state) => [
        ...state,
        { phone: clubInfo.phone, clientCashback: clientCashback.value },
      ]);
    }
  }

  const [toastMessage, setToastMessage] = useState(
    toastUpdateSystemMessage3.toString() === 'true'
  );

  function toggleToast() {
    setToastUpdateSystemMessage3(false);

    setToastMessage(false);
  }

  async function submitClient(client) {
    try {
      const response = await api.post('restaurants/clients/relate', {
        session_id: session.id,
        id: client.id,
      });

      toast.success('Cliente adicionado a comanda com sucesso!');
    } catch (error) {
      toast.error(
        'Não foi possível adicionar o cliente a comanda! tente novamente'
      );
    }
  }
  async function submitClient(client) {
    try {
      const response = await api.post('restaurants/clients/relate', {
        session_id: session.id,
        id: client.id,
      });

      toast.success('Cliente adicionado a comanda com sucesso!');
    } catch (error) {
      toast.error(
        'Não foi possível adicionar o cliente a comanda! tente novamente'
      );
    }
  }

  async function getClient(phone) {
    try {
      const response = await api.post('restaurants/get-clients', {
        phone,
      });

      if (
        response.data.hasClient &&
        session.client?.phone !== response.data.client.phone
      ) {
        submitClient(response.data.client);
      }
    } catch (error) {
      toast.error(
        'Não foi possível obter informações do cliente! tente novamente'
      );
    }
  }

  useEffect(() => {
    const obs_options = restaurantDiscountObs?.map((disc) => ({
      value: disc.id,
      label: disc.obs,
    }));
    setDiscountObsOptions([...obs_options, { value: 'other', label: 'Outro' }]);
  }, [restaurantDiscountObs]);

  useEffect(() => {
    const input = document.getElementById('value')?.focus();
  }, []);

  //end payment cashier

  function handleSetBillsDivide(item, amount, price, id) {
    const billsDivideItemsIndex = billsDivideItems.findIndex(
      (item) => item.id === id
    );
    if (divideByState && billsDivideItemsIndex === -1) {
      const finalPrice = price / divideByState.value;
      const finalAmount = amount / divideByState.value;

      setBillsDivideItems((state) => {
        return [
          ...state,
          { id, amount: finalAmount, name: item, price: finalPrice },
        ];
      });

      setValue((state) => (Number(finalPrice) + Number(state)).toFixed(2));

      if (left > 0) {
        setLeft(Number(finalPrice) + Number(left));
      }
      // setLeft(state => (Number(finalPrice) + Number(state || 0)).toFixed(2))
      setButtonsActive([]);
      setDivideByState('');
    } else if (billsDivideItemsIndex !== -1) {
      toast.error('Item já inserido', {
        style: {
          border: '1px solid darkOrange',
          padding: '16px',
          color: 'darkOrange',
        },
        iconTheme: {
          primary: 'darkOrange',
          secondary: '#FFFAEE',
        },
      });
      setDivideByState('');
      setButtonsActive([]);
    } else if (!divideByState) {
      toast.error('Inserir quantidade');
      setDivideByState('');
    }
  }

  const divide_by_options = [{ label: 'Sem divisão', value: 1 }];

  for (let i = 2; i <= 20; i++) {
    divide_by_options.push({
      label: i,
      value: i,
    });
  }

  function handleDeleteBillDivide(id) {
    const billsDivideItemsIndex = billsDivideItems.findIndex(
      (item) => item.id === id
    );

    if (billsDivideItemsIndex !== -1) {
      const itensFiltered = billsDivideItems.filter((item) => item.id !== id);

      setBillsDivideItems(itensFiltered);
    } else {
      toast.error('Erro ao deletar item');
    }
  }

  useEffect(() => {
    getBillsSession();
  }, [getBillsSession]);

  const [methodsFiltered, setMethodsFiltered] = useState([]);

  const prohibitedPaymentMethods = [
    CLUBE_METHOD, //  Resgate Clube
    572, //  Pagamento Online iFood
    2548, // Cartão de Crédito Online
    301, //  Pagamento Online Takeat
    50629, //Cupom iFood
  ];

  function handleFilterMethods(method) {
    if (method.length === 0) {
      setMethodsFiltered([]);
      return;
    }

    const paymentsMethodsFiltered = paymentMethods.filter(
      (payment) =>
        payment.available && !prohibitedPaymentMethods.includes(payment.id)
    );

    if (method.length >= 2) {
      const productsFound = paymentsMethodsFiltered.filter((item) =>
        item.label
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            method
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
      );

      setMethodsFiltered(productsFound);
    }
  }

  return (
    <Container>
      <BillsItemsArea>
        <BackButton
          type="button"
          onClick={() => setIsDivideBillsAreaOpen(false)}
        >
          <FaArrowLeft style={{ marginRight: 10 }} />
          Voltar
        </BackButton>

        <Header>
          <h3>Itens na Comanda</h3>
          <span>
            Mesa{' '}
            {session?.table?.table_number === -4
              ? 'POS'
              : session?.table?.table_number === -5
              ? 'Totem'
              : session?.table?.table_number}
          </span>
        </Header>
        {billsState &&
          billsState.length > 0 &&
          billsState.map((bill) => (
            <>
              <PerfilArea size={17} color="#3D3D3D">
                <FaUserCircle />
                <strong>
                  {bill?.buyer
                    ? formatPhone(bill?.buyer.phone)
                    : bill?.waiter?.name}
                </strong>
              </PerfilArea>
              <StyledTable>
                <tr>
                  <th>Qtde</th>
                  <th>Item</th>
                  <th>
                    Valor{' '}
                    <FaExclamationCircle
                      style={{ marginBottom: 8 }}
                      color="darkOrange"
                      title="Esse valor já contempla taxa de serviço, se aplicável"
                    />
                  </th>
                </tr>
                <tbody>
                  {bill?.order_baskets?.map((basket) => (
                    <React.Fragment key={basket.id}>
                      {basket.orders.map((order) => (
                        <>
                          <tr>
                            <td>{order?.amount}x</td>
                            <td>{order?.product.name}</td>
                            <td>{formatValue(order?.total_service_price)} </td>
                          </tr>
                          <tr
                            style={{
                              borderBottom: '1px solid #ececec',
                              paddingBottom: 8,
                            }}
                          >
                            <td colSpan="3">
                              <div
                                style={{ display: 'flex', paddingBottom: 8 }}
                              >
                                <SelectStyled
                                  name="divide_by"
                                  placeholder="Dividir por"
                                  options={divide_by_options}
                                  onChange={(e) => {
                                    setDivideByState({
                                      value: e.value,
                                      label: e.label,
                                    });
                                    handleSetButtonsActive(order?.id);
                                  }}
                                  value={
                                    !buttonsActive.includes(order?.id)
                                      ? ''
                                      : divideByState
                                  }
                                />

                                <ButtonAddBill
                                  type="button"
                                  onClick={() => {
                                    handleSetBillsDivide(
                                      order?.product.name,
                                      order?.amount,
                                      order?.total_service_price,
                                      order?.id
                                    );
                                  }}
                                  disabled={!buttonsActive.includes(order?.id)}
                                >
                                  <FaPlus />
                                  Inserir
                                </ButtonAddBill>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </StyledTable>
            </>
          ))}
      </BillsItemsArea>
      <PartialBillArea>
        <h3>Comanda parcial</h3>
        <Header>
          <span>
            {billsDivideItems && billsDivideItems.length > 0
              ? formatValue(
                  billsDivideItems.reduce((accum, curr) => {
                    return accum + Number(curr.price);
                  }, 0)
                )
              : 'R$ 0,00'}
          </span>
          <ReactToPrint
            trigger={() => (
              <PrintButton type="button">
                <FaPrint />
                Imprimir
              </PrintButton>
            )}
            content={() => componentRef.current}
          />
        </Header>

        <StyledTable>
          <tr>
            <th>Qtde</th>
            <th>Item</th>
            <th>Valor</th>
            <td />
          </tr>
          <tbody>
            {billsDivideItems &&
              billsDivideItems.length > 0 &&
              billsDivideItems.map((item) => (
                <tr>
                  <td>{Number(item.amount).toFixed(2)}x</td>
                  <td>{item.name}</td>
                  <td>{formatValue(item.price)}</td>
                  <td>
                    <FaTrash
                      color="ff2c3a"
                      onClick={() => handleDeleteBillDivide(item.id)}
                      cursor="pointer"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </StyledTable>
      </PartialBillArea>
      <PaymentArea>
        <Header style={{ marginTop: 0 }}>
          <h3>Mesa {session.table.table_number}</h3>
          <FiX
            size={22}
            color="#FF2129"
            onClick={() => setIsDivideBillsAreaOpen(false)}
            cursor="pointer"
          />
        </Header>

        {user.has_clube && (
          <Form
            style={{
              borderBottom: '1px solid #dee2e6',
            }}
          >
            {' '}
            <p style={{ fontSize: 13 }}>
              Insira as informações do cliente para resgatar o cashback
            </p>
            <RescueContainer>
              {loadingCashback && (
                <Spinner
                  style={{
                    position: 'absolute',
                    top: 49,
                    left: 135,
                    zIndex: 10,
                    height: 15,
                    width: 15,
                  }}
                />
              )}

              <InputMask
                mask="(99) 99999-9999"
                maskPlaceholder={null}
                defaultValue={clubInfo.phone}
                value={clubInfo.phone}
                onChange={(e) => {
                  const newText = e.target.value;
                  setClubInfo((state) => {
                    return { ...state, phone: newText };
                  });
                }}
              >
                <Input
                  label="Celular:"
                  name="club-phone"
                  type="text"
                  placeholder="(xx) xxxxx-xxxx"
                />
              </InputMask>

              <RescueDate>
                <InputMask
                  id="date"
                  mask="99/99/9999"
                  maskPlaceholder={null}
                  defaultValue={clubInfo.date}
                  value={clubInfo.date}
                  onChange={(e) => {
                    const newText = e.target.value;
                    setClubInfo((state) => {
                      return { ...state, date: newText };
                    });
                  }}
                >
                  <Input
                    label="Aniversário:"
                    name="club-birthday"
                    type="text"
                    placeholder="dd/mm/aaaa"
                  />
                </InputMask>

                <RescueButton
                  disabled={
                    format(new Date(clientCashback.date), 'dd/MM/yyyy') !==
                      clubInfo.date || parseFloat(clientCashback.value) <= 0
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      payments.find(
                        (p) => p.payment_method_id === CLUBE_METHOD
                      ) ||
                      session.payments.find(
                        (p) => p.payment_method_id === CLUBE_METHOD
                      )
                    ) {
                      toast.error(
                        'Resgate do clube já foi adicionado ao pagamento desta comanda.'
                      );
                    } else if (parseFloat(clientCashback.value) > 0) {
                      setMetodoAndPaymentOption({
                        label: 'Resgate Clube',
                        id: CLUBE_METHOD,
                      });
                    }
                  }}
                >
                  <span>Resgatar</span>
                  <span> R$ {clientCashback.value}</span>
                </RescueButton>
              </RescueDate>
            </RescueContainer>
          </Form>
        )}

        {(session?.table?.table_type === 'delivery' ||
          session?.table?.table_type === 'balcony') && (
          <>
            <Form>
              <CheckboxInput
                label="Aplicar desconto"
                name="apply"
                value={applyDiscount}
                onChange={() => setApplyDiscount(!applyDiscount)}
              />
            </Form>
            {applyDiscount && (
              <>
                <span style={{ color: 'grey' }}>Desconto</span>
                <div
                  style={{
                    display: 'flex',
                    margin: '10px 0 0',
                    position: 'relative',
                  }}
                >
                  <input
                    name="flat_discount"
                    type="text"
                    placeholder="R$"
                    style={{ width: '50%', padding: '3px 10px' }}
                    value={discount ? 'R$' + discount : ''}
                    onChange={(e) => handleChangeDiscount(e.target)}
                  />
                  <input
                    name="discount"
                    placeholder="%"
                    type="number"
                    style={{
                      width: '50%',
                      padding: '3px 10px',
                      marginLeft: '5px',
                    }}
                    value={discountPercent ? discountPercent : ''}
                    onChange={(e) => handleChangeDiscount(e.target)}
                  />
                  {discount && (
                    <p
                      style={{
                        position: 'absolute',
                        right: '15px',
                        top: '5px',
                      }}
                    >
                      %
                    </p>
                  )}
                </div>
                <Form>
                  <span style={{ color: 'grey', marginTop: '10px' }}>
                    Motivo
                  </span>
                  <Select
                    label={null}
                    name="discount_obs"
                    options={discountObsOptions}
                    onChange={(e) => {
                      if (e.value === 'other') {
                        setDiscountText(true);
                      } else {
                        setDiscountText(false);
                        setDiscountObs(e.label);
                      }
                    }}
                  />
                  {discountText && (
                    <Input
                      type="text"
                      placeholder="Escreva o motivo"
                      id="discount_obs"
                      name="discount_obs"
                      onChange={(e) => setDiscountObs(e.target.value)}
                    />
                  )}
                  <BlueButton onClick={(e) => addDiscountToSession(e)}>
                    Aplicar
                  </BlueButton>
                </Form>
              </>
            )}
          </>
        )}

        <InnerArea>
          <InformationDiv>
            <Total>
              <span>Total</span>
              <h4 style={{ fontWeight: 'bold' }}>
                {billsDivideItems && billsDivideItems.length > 0
                  ? formatValue(
                      billsDivideItems.reduce((accum, curr) => {
                        return accum + Number(curr.price);
                      }, 0)
                    )
                  : 'R$ 0,00'}
              </h4>
            </Total>
            <Paid>
              <span>{left > 0 ? 'Restante' : 'Troco'}</span>
              <h4 style={{ fontWeight: 'bold' }}>
                R$
                {left > 0 ? left.toFixed(2) : (left * -1).toFixed(2)}
              </h4>
            </Paid>
            <Form>
              <InputPaymentForm
                placeholder="R$"
                type="number"
                step="0.01"
                min="0"
                name="value"
                id="value"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </Form>
          </InformationDiv>

          <PaymentDiv>
            <div
              style={{
                position: 'fixed',
                top: 300,
                left: 290,
                width: '415px',
                height: 210,
                backgroundColor: '#fff',
                borderRadius: 7,
                padding: 20,
                filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))',
                display: toastMessage ? 'flex' : 'none',
                zIndex: 2000000,
              }}
            >
              <div style={{}}>
                <p style={{ fonteWeight: 'bold', fontSize: 18 }}>
                  <FaExclamationTriangle color="darkOrange" />{' '}
                  <strong>Atenção, nova Atualização!</strong>
                </p>
                <span>
                  Agora é possível favoritar os métodos de pagamento para que
                  eles apareçam nessa lista e agilize ainda mais a sua escolha.
                  Se quiser alterar o método de pagamento favorito,{' '}
                  <Link to="/box/payment"> clique aqui.</Link>
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <button
                  type="button"
                  style={{
                    background: '#3BD2C1',
                    color: '#fff',
                    width: 54,
                    height: 48,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    borderRadius: 7,
                    marginLeft: 10,
                    fontWeight: 'bold',
                    border: 'none',
                  }}
                  onClick={(e) => toggleToast(false)}
                >
                  OK
                </button>
              </div>
            </div>

            <AddPaymentForm>
              {favoriteMethods.map((payment) =>
                payment.available &&
                !prohibitedPaymentMethods.includes(payment.id) ? (
                  <div key={payment.id}>
                    <PaymentMethodButton
                      onClick={() => setMetodoAndPaymentOption(payment)}
                      disabled={insertingPayment}
                    >
                      {payment.label}
                    </PaymentMethodButton>
                  </div>
                ) : (
                  <></>
                )
              )}

              {paymentMethods.length > 0 && favoriteMethods.length >= 8 && (
                <Dropdown
                  isOpen={dropdownOpen}
                  size="sm"
                  toggle={toggleDrop}
                  direction="left"
                >
                  <DropdownToggle
                    color="danger"
                    caret
                    style={{ width: '100%', height: '40px', margin: 0 }}
                  >
                    {metodo}
                  </DropdownToggle>
                  <DropdownMenu
                    style={{
                      overflowY: 'scroll',
                      height: 400,
                      paddingBottom: 200,
                    }}
                  >
                    <IconInput
                      color="#6c6c6c"
                      placeholder="Buscar"
                      isClearable
                      deleteButton={() => setMethodsFiltered([])}
                      containerStyles={{
                        height: 40,
                        border: 'none',
                        borderBottom: '1px solid #6c6c6c',
                        borderRadius: 0,
                      }}
                      onChange={(e) => {
                        handleFilterMethods(e.target.value);
                      }}
                    />
                    {methodsFiltered && methodsFiltered.length > 0
                      ? methodsFiltered.map((payment) => (
                          <DropdownItem
                            onClick={() => setMetodoAndPaymentOption(payment)}
                            disabled={insertingPayment}
                          >
                            {payment.label}
                          </DropdownItem>
                        ))
                      : paymentMethods.map((payment) =>
                          payment.available &&
                          !prohibitedPaymentMethods.includes(payment.id) ? (
                            <div key={payment.id}>
                              <DropdownItem
                                onClick={() =>
                                  setMetodoAndPaymentOption(payment)
                                }
                                disabled={insertingPayment}
                              >
                                {payment.label}
                              </DropdownItem>
                            </div>
                          ) : (
                            <></>
                          )
                        )}
                  </DropdownMenu>
                </Dropdown>
              )}
            </AddPaymentForm>

            <PaymentTable borderless>
              <tbody>
                {payments?.map((payment) =>
                  payment.payment_method_id === CLUBE_METHOD ? (
                    <tr key={payment.id} style={{ color: '#017957' }}>
                      <td>Resgate Clube</td>
                      <td>R${payment.payment_value}</td>
                    </tr>
                  ) : payment.payment_method.keyword === 'pix_auto' ? (
                    <tr key={payment.id} style={{ color: '#017957' }}>
                      <td>Pix Online</td>
                      <td>R${payment.payment_value}</td>
                    </tr>
                  ) : payment.payment_method.keyword === 'stone_credit' ? (
                    <tr key={payment.id} style={{ color: '#017957' }}>
                      <td>Stone Crédito</td>
                      <td>R${payment.payment_value}</td>
                    </tr>
                  ) : (
                    payment.payment_method.keyword === 'stone_debit' && (
                      <tr key={payment.id} style={{ color: '#017957' }}>
                        <td>Stone Débito</td>
                        <td>R${payment.payment_value}</td>
                      </tr>
                    )
                  )
                )}
                {payments.map(
                  (payment) =>
                    payment.payment_method_id !== CLUBE_METHOD &&
                    payment.payment_method.keyword !== 'pix_auto' &&
                    payment.payment_method.keyword !== 'stone_credit' &&
                    payment.payment_method.keyword !== 'stone_debit' && (
                      <tr key={payment.id}>
                        {allPaymentMethods
                          .filter(
                            (method) => method.id === payment.payment_method_id
                          )
                          .map((method) => (
                            <td key={method.id}>{method.label}</td>
                          ))}
                        <td>R${payment.payment_value}</td>
                        <td />
                        <td>
                          <div>
                            <DeletePaymentForm
                              onClick={() => deletePayments(payment.id)}
                            >
                              <FiTrash size={15} />
                            </DeletePaymentForm>
                          </div>
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </PaymentTable>
            {/* <FinalDiv>
        <Total>
          <span>Pago</span>
          <h4>R${paid}</h4>
        </Total>
        <Paid>
          <span>Pago</span>
          <h4>{porcent}%</h4>
        </Paid>
      </FinalDiv> */}
          </PaymentDiv>
        </InnerArea>

        <ButtonsArea>
          <p style={{ fontSize: 12 }}>
            Não é possível emitir NFC-e / NFe para pagamentos parciais. A
            emissão é feita apenas pelo Caixa.
          </p>
          <div>
            {user.is_printed_on_web || user.print_bills_on_web ? (
              <ReactToPrint
                onBeforeGetContent={async () => {
                  await getBillsSession();
                }}
                trigger={
                  !loadingBills
                    ? () => (
                        <PersonalButton
                          color="#FF2C3A"
                          message="Fechar comanda"
                          style={{
                            marginLeft: 0,
                            height: 46,
                            lineHeight: '20px',
                          }}
                          onClick={() => {
                            handlePrintItem(session.id);
                          }}
                        />
                      )
                    : () => <Spinner color="danger" type="grow" />
                }
                content={() => componentPrintRef.current}
                onAfterPrint={() => {
                  handlePrintItem({
                    session_key: session.key,
                  });
                  setAllTablesAvailable('');
                  setInputValue('');
                  setTablesFilteredSearch('');
                  if (!sessionsAwarded.includes(session.id) && user.has_clube) {
                    handlePontuarSession({
                      session_key: session.key || session.session_key,
                    });
                  } else {
                    toast.error('Sessão já pontuada!');
                  }
                }}
              />
            ) : (
              <PersonalButton
                color="#FF2C3A"
                message="Fechar comanda"
                style={{
                  marginLeft: 0,
                  height: 46,
                  lineHeight: '20px',
                }}
                onClick={() => {
                  printClick();
                }}
              />
            )}

            <PersonalButton
              style={{
                height: 46,
                lineHeight: '20px',
              }}
              color="#0CCAB4"
              message="Confirmar pagamento"
              type="button"
              onClick={() => {
                setIsDivideBillsAreaOpen(false);
              }}
            />
          </div>
        </ButtonsArea>
      </PaymentArea>

      <DisplayNoneImprimir>
        <PrintBillsDivide
          item={session}
          billsDivideItems={billsDivideItems}
          ref={componentRef}
        />
      </DisplayNoneImprimir>

      <DisplayNoneImprimir>
        <PrintBillsTableOperation
          item={session}
          restaurant={user}
          ref={componentPrintRef}
          paymentData={paymentsBill}
          printBills={printBills}
          sessionBills={sessionBills}
          count={count}
          productBills={productBills}
          commandTableNumber={commandTableNumber}
        />
      </DisplayNoneImprimir>
    </Container>
  );
}

export default DivideBillsArea;
