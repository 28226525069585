import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Modal,
  Carousel,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';


export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  gap: 4px;
`;

export const VideoContainer = styled.div`
  position: relative;
`;
export const LinkAndVideoDiv = styled.div`
  display: flex;
  flex-direction: row;

  `;

export const LiveButton = styled.button`
  background-color: transparent;
  color: ${(props) => (props.active ? '#2EC9B7' : '#C0C0C0')};
  border: none;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;
export const LiveTextContent = styled.div`
  position: absolute;
  left: 0;
  top: 22px;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  width: fit-content;
  z-index: 200;
  width: 322px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));

  p {
    font-weight: 500;
    font-size: 16px;
  }
`;

export const DeliveryLink = styled.div`
  text-align: left;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

export const StyledLink = styled(Link)`
  margin-top: 30px;
  color: #fe1933;
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    filter: contrast(1);
    text-decoration: underline;
    color: red;
  }
`;

export const RestaurantInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;

  margin-top: -5px;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};

  span {
    margin-left: 10px;
    font-size: 16px;
  }
`;

export const Card = styled.div`
  margin: 20px auto;
  width: 90%;
  display: flex;
  padding: 30px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
`;

export const Bloco = styled.div`
  margin: 20px auto;
  width: 32%;
  height: 500px;
  padding: 30px;
  display: flex;
  float: left;
  margin-right: 10px;
  background: red;
  border-radius: 8px;
`;

export const LugarPedidos = styled.div`
  overflow: auto;
  display: flex;
  height: calc(100vh - 176px);
  min-height: 570px;
  background-color: white;
  border-top: none;
  padding: 20px;
  gap: 10px;
  box-shadow: 1px 0px 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  transition: border-radius 0.2s;
  ${(props) => props.first && 'border-top-left-radius: 0px'};

  @media (max-width: 1100x) {
    height: calc(100vh - 203px);
  }
`;

export const InsideNavigation = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
`;

export const ManagerAreaBanner = styled(Modal)`
  max-width: 530px;
  .modal-body {
    padding: 0;
    display: flex;
    overflow: hidden;
  }

  .modal-footer {
    margin-top: 10px;
  }
`;

export const ArrowContainer = styled.div`
  position: absolute;
  ${({ rightArrow }) => (rightArrow ? 'right: 0px;' : 'left: 0px;')}
  top: 50%;
  cursor: pointer;

  svg {
    width: 50px;
    height: 50px;
    color: #f6f6f6;
    filter: drop-shadow(0px 0px 5px rgb(0 0 0 / 1));
    ${({ rightArrow }) => (rightArrow ? 'transform: rotate(180deg);' : '')};
    stroke-width: 20;
  }
`;

export const SquareButton = styled.button`
  position: relative;
  display: flex;
  width: 50px;
  height: 40px;
  padding: 0px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 5px;
  border: 1px solid transparent;
  background: #fff;
  color: ${({ color }) => color ? color : '#ff2c3a'};
  transition: all 0.2s;

  &:hover {
    border: 1px solid ${({ color }) => color ? color : '#ff2c3a'};
  }

  span {
    color: ${({ color }) => color ? color : '#ff2c3a'};
    text-align: center;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: 7px;
  }
`;

export const SearchInput = styled.div`
  width: 240px;
  display: flex;
  padding: 10px 20px;
  align-items: center;
  gap: 20px;
  border-radius: 5px;
  border: 1px solid #CDCDCD;
  background-color: #fff;
  input {
    width: 100%;
    flex: 1;
    color: #6C6C6C;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    border: none;
  
    &::placeholder {
      color: #CDCDCD;
    }

    &:focus-visible {
      outline: none;
    }
  }
`;


export const Overlay = styled.div`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
      `;

export const ModalIfood = styled.div`

      background-color:white;
      position:fixed;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
      z-index:100;
      padding:24px;
      border-radius:12px;
      max-width:550px;

      .close-button{
        position: absolute;
        height:0px;
        background:none;
        border:none;
        padding:0;
        display:flex;
        justify-content:flex-end;
        max-width:90%;
      }

      body {
        background-color:white;
        display:flex;
        flex-direction:column;
      }

      div{
        display:flex;
        flex-direction:column;
        align-items:center;
        gap:16px;
        text-align:center;
      }

      h1{
        font-weight:600;
        font-size:24px;
        margin:0;
      }

      a{
        padding-top:24px;
      }

      p{
        font-weight:500;
        font-size:18px;
      }

      img{
        padding-bottom:24px;
        width:auto;
        height:300px;
      }

      button {
        width:100%;
        padding:10px 0px;
        background-color:#C8131B;
        border-radius:12px;
        border:none;

        font-weight:600;
        font-size:16px;
        font-family: poppins, sans-serif;
      }
    `
export const CarouselStyled = styled(Carousel)`
  width: 100%;

  img {
    width: 500px;
  }
`;

export const CarouselIndicatorsStyled = styled(CarouselIndicators)`
  margin-bottom: 80px;
  button {
    background-color: #33333370 !important;
  }
`;

export const CarouselControlStyled = styled(CarouselControl)`
  button {
    fill: #2ec9b7 !important;
    color: #2ec9b7 !important;
  }
`;