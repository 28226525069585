import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import { AiOutlinePrinter } from 'react-icons/ai';
import { toast } from 'react-hot-toast';

import { Container, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { FaCheck, FaEye, FaPlus, FaPrint, FaRegClock } from 'react-icons/fa';
import api from '~/services/api';
import DeleteItemOrders from '~/components/Orders/DeleteItemOrders';
import { OrderNumber, PhoneNumber, BillInfo, UserDeliveryInfo, DateArea, CardHeader, CardBody, UserDeliveryInfoArea } from '../styles';
import {
  Print,
  DisplayNoneImprimir,
  ModalPaymentBody,
  PrintOutline,
  ContainerOrders,
  Footer,
  ContentBody,
  Actions,
  InnerArea,
  Badge
} from './styles';

import EyeModalOrders from '~/components/EyeModalOrders';
import PrintNewOrderComponent from '~/components/PrintNewOrderComponent';
import { useAuth } from '~/context/AuthContext';
import FooterNF from '~/components/FooterNF';
import PaymentFormDelivery from '~/components/Delivery/PaymentFormDelivery';

import logoTakeat from '../../../../assets/img/garfinho_red.png';
import logoIfood from '../../../../assets/img/ifood-logo.png';
import { useMotoboys } from '~/context/MotoboysContext';
import { ActionButton, Button, DefaultSelect } from 'ui-kit-takeat';
import CreateMotoboy from '~/pages/Motoboys/Create';
import TableEditDelivery from '~/components/Operation/TablesOperation/TableEditDelivery';
import formatDateYYYY from '~/services/formatDateYYYY';
import formatTime from '~/services/formatTime';

export default function OrderReadyDelivery({
  item,
  handleItemFinished,
  handleCancelItem,
  getAllOrders,
  handleUpdate,
  paymentMethods,
  allPaymentMethods,
  favoritePaymentMethods,
}) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [btnDisable, serBtnDisable] = useState(false);
  const [creatingMotoboy, setCreatingMotoboy] = useState(false);

  const { motoboys } = useMotoboys();
  const [paymentsState, setPaymentsState] = useState([]);
  const [applyDiscount, setApplyDiscount] = useState(!!item.discount_total);
  const [discount, setDiscount] = useState(item.discount_total || '');
  const [discountObs, setDiscountObs] = useState(item.discount_obs || '');
  const [discountObservation, setDiscountObservation] = useState(false);
  const [adminPassword, setAdminPassword] = useState(null);
  const [thisItemMotoboy, setThisItemMotoboy] = useState(item.motoboy);

  const [modalEye, setModalEye] = useState(false);
  function toggleEye() {
    setModalEye(!modalEye);
    // getPayments();
  }

  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);

  const [modalDeleteDelivery, setModalDeleteDelivery] = useState(false);
  const toggleDeleteDelivery = () => {
    setModalDeleteDelivery(!modalDeleteDelivery);
    serBtnDisable(!btnDisable);
  };

  const [orders, setOrders] = useState([]);
  const getOrders = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/basket-orders/${item.basket.id}`
      );

      setOrders(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [item]);

  const getPayments = useCallback(async () => {
    const response = await api.get('restaurants/payments', {
      params: { table_session_id: item.session_id },
    });

    const parsedData = response.data.payments.map((data) => ({
      id: data.id,
      value: data.payment_value,
      payment_method_id: data.payment_method_id,
      removable: data.removable,
      label: data.label,
      keyword: data.payment_method.keyword
    }));

    setPaymentsState(parsedData);
  }, [item.session_id]);

  useEffect(() => {
    if (modalDelete) {
      getOrders();
    }
  }, [modalDelete, getOrders]);

  const { user } = useAuth();
  const componentRef = useRef(null);

  async function printClick() {
    try {
      await api.post('restaurants/printers/printer-queue', {
        order_basket_id: item.basket.id,
        order_type: 'new_order',
      });
      toast.success('Pedido impresso.');
    } catch (error) {
      toast.error('Erro ao imprimir pedido.');
    }
  }

  const userName = item?.buyer
    ? item?.buyer?.ifood_phone
      ? item?.buyer?.ifood_phone
      : item?.buyer?.phone
    : item?.waiter?.name;

  const handleUpdateMotoboy = async (data) => {
    if (data) {
      const id = data.id || data;
      try {
        await api.post('/restaurants/motoboys/relate', {
          session_id: item.session_id,
          motoboy_id: id,
        });

        const updatedMotoboy = motoboys.find((m) => m.id === id);

        if (updatedMotoboy) {
          item.motoboy = updatedMotoboy;
          setThisItemMotoboy({
            value: updatedMotoboy.id,
            label: updatedMotoboy.name,
          });
        }

        toast.success('Motoboy atualizado com sucesso!');
      } catch (err) {
        console.log('Error handleUpdateMotoboy: ', err);
        toast.error('Erro ao atualizar motoboy');
      }
    } else {
      try {
        await api.post('/restaurants/motoboys/relate', {
          session_id: item.session_id,
          motoboy_id: null,
        });

        setThisItemMotoboy({
          value: null,
          label: "Retirar motoboy",
        });


        toast.success('Motoboy atualizado com sucesso!');
      } catch (err) {
        console.log('Error handleUpdateMotoboy: ', err);
        toast.error('Erro ao atualizar motoboy');
      }
    }

  };

  function searchTableName(table) {
    if (table.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table.table_type === 'pos') {
      return 'POS';
    }

    if (table?.table_type === 'self-service') {
      return 'Totem';
    }

    if (table.table_number === -10) {
      return 'Mesa Copa';
    }

    if (table.table_type === 'delivery') {
      if (item?.with_withdrawal && item?.scheduled_to) {
        return 'Retirada Agendada';
      }
      if (item?.with_withdrawal) {
        return 'Retirada';
      }

      if (!item?.with_withdrawal && item?.scheduled_to) {
        return 'Delivery Agendado';
      }

      if (table.table_type === 'prepaid') {
        return `Mesa  ${table.table_number} - Pagamento Antecipado`;
      }

      return 'Delivery';
    }

    return `Mesa  ${table.table_number}`;
  }

  const handleDiscountUpdate = useCallback(async () => {
    if (item?.discount_total !== discount && discount > 0) {
      try {
        const response = await api.put(
          `/restaurants/delivery/orders/update/${item?.session_id}`,
          {
            discount: applyDiscount ? parseFloat(discount) : null,
            discount_obs: applyDiscount ? discountObservation ? `${discountObs} - ${discountObservation}` : discountObs : null,
            user_id: user.user_id,
            password: adminPassword,
          }
        );

        console.log('res > ', response.data);
      } catch (err) {
        console.log('Error handleDiscountUpdate: ', err);
      }
    }
  }, [discount, discountObs, applyDiscount, adminPassword, discountObservation]);

  useEffect(() => {
    if (modalEye) {
      try {
        getPayments();
      } catch (error) {
        toast.error('Erro ao carregar informações');
      }
    }
  }, [getPayments, modalEye]);

  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  const [loadingPrinter, setLoadingPrinter] = useState(false);

  async function printDeliverySession() {
    setLoadingPrinter(true);
    try {
      await api.post('restaurants/printers/printer-queue', {
        table_session_id: item.session_id,
        is_delivery: true,
      });

      toast.success('Pedido enviado para impressão.');
    } catch (error) {
      toast.error('Erro ao imprimir pedido.');
    }
    setLoadingPrinter(false);
  }

  useEffect(() => {
    if (item.motoboy) {
      setThisItemMotoboy({ value: item.motoboy.id, label: item.motoboy.name });
    }
  }, [item]);

  const [motoboysOptions, setMotoboysOptions] = useState(false)

  useState(() => {
    const motoboysOld = {
      value: null,
      label: "Retirar motoboy",
    }
    const newMotoboys = motoboys?.map((m) => {
      return {
        value: m.id,
        label: m.name,
      };
    })

    setMotoboysOptions([motoboysOld, ...newMotoboys])
  }, [])

  const [storeFoodyLoading, setStoreFoodyLoading] = useState(false);
  const sendStoreFoodySession = async () => {
    setStoreFoodyLoading(true);
    await api.post('restaurants/foody-delivery/store', {
      session_id: item.session_id,
    }).then((response) => {
    }).catch((error) => {
      if (error.response.data.errorType === 'with_withdrawal') {
        toast.error('Pedido com retirada não pode ser enviado para o Foody');
        return;
      }
      if (error.response.data.errorType === 'session_sent') {
        toast.error('Sessão já enviada para o Foody');
        return;
      }
      toast.error('Erro ao enviar sessão para o Foody');
    }).finally(() => {
      setStoreFoodyLoading(false);
    })
  }

  return (
    <>
      <ContentBody>
        <CardHeader>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <BillInfo>
              <img
                src={item.basket.ifood_id ? logoIfood : logoTakeat}
                style={{ width: item.basket.ifood_id ? 38 : 20, height: 25 }}
              />
              <OrderNumber>#{item?.attendance_password}</OrderNumber>
              <OrderNumber>
                {item?.table.table_type === 'command' &&
                  item?.table.table_type !== 'delivery' ? (
                  `Comanda  ${item.table.table_number} / Mesa ${item?.basket?.command_table_number || 'não informada'
                  } `
                ) : (
                  <>
                    {item?.basket?.ifood_table
                      ? item?.basket?.ifood_table
                      : searchTableName(item?.table)}
                  </>
                )}
              </OrderNumber>
            </BillInfo>
            {item?.ifood_restaurant?.name && (
              <p style={{ textAlign: "left" }}>
                {item.ifood_restaurant.name}
              </p>
            )}
            {user.use_foody_delivery && !item.foody_delivery_session_id && (
              <>
                {storeFoodyLoading ? (
                  <Spinner size="sm" color="danger" />
                ) : (
                  <Badge
                    color="#C8131B"
                    disabled={storeFoodyLoading}
                    onClick={sendStoreFoodySession}
                  >
                    Não Vinculado ao Foody
                  </Badge>
                )}
              </>
            )}
          </div>

          <span>
            {item?.scheduled_to
              ? (
                <DateArea>
                  <span style={{ fontSize: 12 }}>Agendado para:</span>
                  <strong>
                    {formatDateYYYY(item?.scheduled_to)}
                  </strong>
                  <span>
                    <FaRegClock /> {formatTime(item?.scheduled_to)}
                  </span>
                </DateArea>
              )
              : (
                <DateArea>
                  <strong>
                    {formatDateYYYY(item?.basket.start_time)}
                  </strong>
                  <span>
                    <FaRegClock /> {formatTime(item?.basket.start_time)}
                  </span>
                </DateArea>
              )}
          </span>
        </CardHeader>
        <CardBody>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            {item?.buyer?.name && (
              <PhoneNumber style={{ fontWeight: 600 }}>{item.buyer.name}</PhoneNumber>
            )}
            {item?.basket?.schedule && (

              <PhoneNumber>
                <strong>{item?.basket?.schedule}</strong>
              </PhoneNumber>

            )}
            <PhoneNumber>{userName}</PhoneNumber>
            {
              !item?.with_withdrawal && (
                <UserDeliveryInfoArea>
                  {item?.buyer?.delivery_address?.neighborhood && (
                    <UserDeliveryInfo>
                      Bairro:  {item?.buyer?.delivery_address?.neighborhood}
                      - {item?.buyer?.delivery_address?.city}
                    </UserDeliveryInfo>
                  )}

                  {item?.buyer?.delivery_address?.zip_code && (
                    <UserDeliveryInfo>
                      CEP:  {item?.buyer?.delivery_address?.zip_code}
                    </UserDeliveryInfo>
                  )}
                </UserDeliveryInfoArea>
              )
            }
            {item?.motoboy?.name && (
              <UserDeliveryInfo style={{ marginTop: 8 }}>
                <strong>Motoboy:</strong> {item?.motoboy?.name}
              </UserDeliveryInfo>
            )}

          </div>
          <Actions>
            <FaCheck color="#3BD2C1" size={18} onClick={toggleEye} />
            <FaEye color="#FFA814" size={20} onClick={toggle} />
            <TableEditDelivery
              item={item}
              toggleDeleteDelivery={toggleDeleteDelivery}
              handleUpdate={handleUpdate}
              orderReady={true}
            />
          </Actions>
        </CardBody>

        <Modal isOpen={modal} toggle={toggle}>
          <ModalBody>
            <ContainerOrders browserZoomLevel={browserZoomLevel}>
              <EyeModalOrders item={item} />
            </ContainerOrders>
          </ModalBody>
          <Footer
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 20,
            }}
          >
            {user.is_printed_on_web ? (
              <ReactToPrint
                onBeforeGetContent={async () => {
                  await getOrders();
                }}
                trigger={() => (
                  <Print>
                    <FaPrint color="#fff" size={20} />
                    Imprimir
                  </Print>
                )}
                content={() => componentRef.current}
              />
            ) : (
              <>
                {item?.table.table_type === 'delivery' ? (
                  // <ReactToPrint
                  //   onBeforeGetContent={async () => {
                  //     await getOrders();
                  //   }}
                  //   trigger={() => (
                  //     <Print>
                  //       <AiOutlinePrinter color="black" size={20} />
                  //       oi
                  //     </Print>
                  //   )}
                  //   content={() => componentRef.current}
                  // />

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <PrintOutline onClick={printClick}>
                      <FaPrint color="#ff2c3a" size={20} />
                      Imprimir em ambiente de produção
                    </PrintOutline>
                    {user.print_bills_delivery_on_web ? (
                      <ReactToPrint
                        onBeforeGetContent={async () => {
                          await getOrders();
                        }}
                        trigger={() => (
                          <Print>
                            <FaPrint color="#fff" size={20} />
                            Imprimir via de entrega
                          </Print>
                        )}
                        content={() => componentRef.current}
                      />
                    ) : (
                      <Print
                        onClick={printDeliverySession}
                        disabled={loadingPrinter}
                      >
                        <FaPrint color="#fff" size={20} />
                        Imprimir via de entrega
                      </Print>
                    )}
                  </div>
                ) : (
                  <Print onClick={printClick}>
                    <AiOutlinePrinter color="black" size={20} />
                  </Print>
                )}
              </>
            )}
          </Footer>
        </Modal>

        <Modal isOpen={modalDeleteDelivery} toggle={toggleDeleteDelivery}>
          <ModalBody>
            <Container>
              <h3>Tem certeza que deseja recusar o pedido?</h3>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              inverted
              onClick={() => toggleDeleteDelivery()}
              title='Desistir'
            />
            <Button
              onClick={() =>
                handleCancelItem({
                  from: 'accepted',
                  order_basket_id: item.basket.id,
                  session_id: item.session_id,
                })
              }
              title=' Recusar o pedido'
            />
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalDelete} toggle={toggleDelete}>
          <ModalBody>
            <Container>
              <DeleteItemOrders
                item={item}
                modalDelete={modalDelete}
                from="accepted"
                orders={orders}
              />
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              title='Cancelar esse(s) item(s)'
              onClick={() =>
                handleCancelItem({
                  order_basket_id: item.basket.id,
                  from: 'accepted',
                })
              }
            />
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalEye} toggle={toggleEye}>
          <InnerArea browserZoomLevel={browserZoomLevel}>
            <ModalPaymentBody>
              <PaymentFormDelivery
                item={item}
                payments_session={paymentsState}
                // getPayments={getPayments}
                setPaymentsState={setPaymentsState}
                discount={discount}
                discountObs={discountObs}
                setDiscount={setDiscount}
                setDiscountObs={setDiscountObs}
                setDiscountObservation={setDiscountObservation}
                applyDiscount={applyDiscount}
                setApplyDiscount={setApplyDiscount}
                adminPassword={adminPassword}
                setAdminPassword={setAdminPassword}
                paymentMethods={paymentMethods}
                allPaymentMethods={allPaymentMethods}
                favoritePaymentMethods={favoritePaymentMethods}
              />
            </ModalPaymentBody>

            {motoboysOptions && motoboysOptions.length > 0 && !item.ifood_on_demand_id && (
              <div
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '15px',
                }}
              >

                <DefaultSelect
                  options={motoboysOptions}
                  placeholder="Motoboy"
                  name="motoboy"
                  label="Motoboy"
                  value={thisItemMotoboy}
                  onChange={(e) => handleUpdateMotoboy(e.value)}
                />
                <ActionButton
                  buttonColor="#2ec9b7"
                  icon={<FaPlus />}
                  minWidth={40}
                  onClick={() => setCreatingMotoboy(true)}
                />
              </div>
            )}

            <Modal isOpen={creatingMotoboy}>
              <CreateMotoboy
                toggleModalRegisterMotoboy={() => setCreatingMotoboy(false)}
                motoboyCreated={(data) => {
                  console.log('motoboy created data > ', data);
                  handleUpdateMotoboy(data);
                  setThisItemMotoboy({ value: data.id, label: data.name });
                  setCreatingMotoboy(false);
                }}
              />
            </Modal>

            <FooterNF
              session={item}
              toggleEye={toggleEye}
              getSessions={getAllOrders}
              payments={paymentsState}
              has_nfce={user.has_nfce}
              item={item}
              handleItemFinished={handleItemFinished}
              motoboys={motoboys}
              handleDiscountUpdate={handleDiscountUpdate}
              discount={discount}
              discountObs={discountObs}
              applyDiscount={applyDiscount}
              adminPassword={adminPassword}
              user={user}
            />

          </InnerArea>
        </Modal>
      </ContentBody>
      <DisplayNoneImprimir>
        <PrintNewOrderComponent
          restaurant={user}
          item={item}
          ref={componentRef}
          orders={orders}
        />
      </DisplayNoneImprimir>
    </>
  );
}