import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: auto;
  background: #fff;
  position: relative;
  grid-row: span auto;
  filter: drop-shadow(0px 3px 3px #d9d9d9);
  border-radius: 8px;
  flex-direction: column;
    justify-content: space-between;
    display: flex;
`;

export const Orders = styled.div`
  background: #fff;
  border-radius: 0 0 8px 8px;
`;

export const Header = styled.div`
  background: #EDEDED;
  height: 60px;
  display: flex;
  flex-direction: column;
  padding: 6px;
  border-radius: 8px 8px 0 0;

  ${(props) =>
    props.status === 'pending' &&
    css`
      border-top: 2px solid #01afff ;
    `}

  ${(props) =>
    props.status === 'doing' &&
    css`
      border-top: 2px solid #0ccab4 ;
    `}

  ${(props) =>
    props.status === 'canceled' &&
    css`
      border-top: 2px solid #fe1933;
    `}

  ${(props) =>
    props.status === 'alter' &&
    css`
      border-top: 2px solid #ccbbed;
    `}
`;

export const BuyerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  background: #EDEDED;

  ${(props) =>
    props.isProductLate &&
    css`
      background: #ffa814;
    `}
`;

export const OrdersInfo = styled.main`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  border-bottom: 1px solid #d9d9d9;
`;

export const Main = styled.main`
  
`

export const Footer = styled.footer` 
  width: 100%;
  border-radius: 0 0 8px 8px;
  padding: 10px;
`

export const ConfirmButton = styled.button`
  width: 100%;
  height: 48px;
  background: #01afff;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: bold;

  ${(props) =>
    props.status === 'doing' &&
    css`
      background: #0ccab4;
    `}

  ${(props) =>
    props.status === 'canceled' &&
    css`
      background: #fe1933;
    `}

  ${(props) =>
    props.status === 'alter' &&
    css`
      background: #ccbbed;
    `}
`;

export const ExpandButton = styled.button`
  width: 100%;
  height: 40px;
  background: #f4f4f4;
  position: absolute;
  bottom: 48px;
  border-radius: 0px 0px 5px 5px;
  border: none;
  color: #3d3d3d;
  font-size: 14px;
  font-weight: bold;
`;
