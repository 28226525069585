import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';

export const Container = styled.div`
  height: calc(100vh - 60px);
  padding: 20px;
  overflow-y: scroll;
  padding-bottom: 100px;
`;

export const NavDiv = styled.div`
  display: flex;
  justify-content: space-between;

  margin-left: 70px;
  margin-right: 60px;

  @media (max-width: 1440px) {
    margin-left: 50px;
  }
`;

export const NavDivTeste = styled.div`
  width: 90%;
  padding-left: 10px;
  margin-bottom: 5px;
  margin-left: -6px;
`;

export const NavLinkSpan = styled.span`
  margin-right: 5px;
`;

export const InputsTable = styled.table`
  min-width: 200px;
  width: 100%;
  margin: 15px auto 15px 0;
  border-collapse: initial;
  border-spacing: 0 20px;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid transparent;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  @media (max-width: 1600px) {
  }
`;

export const ErrorContainer = styled.div`
  width: 60%;
  min-width: 600px;
  margin: 10px auto 30px auto;

  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }
`;

export const SearchDiv = styled.div`
  width: fit-content;
  height: 38px;
  padding-bottom: 5px;
  min-width: 200px;
  display: flex;
  gap: 5px;
  align-items: center;
  float: right;
  border-collapse: separate;
  border-spacing: 0 20px;

  [class*='-container'] {
    width: 140px;
  }

  input {
    width: 155px !important;
  }

  @media (min-width: 1600px) {
    margin-right: 0px;
    form {
      input {
        margin-right: 25px;
      }
    }
  }
`;

export const ComplementsTable = styled.div`
  width: 100%;
  min-width: 200px;
  margin: 0 auto;
  box-shadow: 1px 0px 5px 1px rgba(0, 0, 0, 0.1);
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  padding: 0 20px;
`;

export const StyledTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0 20px;
  
  thead {
    text-align: left;
    tr{
      th:first-child{
        text-align:left !important;
      }
      th{
        border-bottom:1px solid #C4C4C4 !important;
        padding-bottom:24px;
        text-align: center !important;
      }
      th:last-child {
        text-align: right;
      }
    }
  }

  tbody {
    tr{
      td:first-child{
        text-align:left !important;
      }
      td
        {
          border-bottom: 1px solid #F4F4F4 !important;
          text-align: center !important;
        }
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const DeleteFilterButton = styled.button`
  width: 78px;
  height: 38px;
  border: none;
  background: #3bd2c1;
  color: #fff;
  padding: 8px 10px;
  border-radius: 12px;
`;

export const InventaryButton = styled.button`
  border: none;
  background: transparent;
  color: #3bd2c1;
  font-weight: bold;
  border-radius: 4px;
  text-decoration: underline;
  font-size: 16px;
`;
