import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';

// import Dashboard from '../pages/Dashboard';
import ProfilePage from '~/pages/ProfilePage';

import Products from '../pages/Products';
import CreateProduct from '../pages/Products/Create';
import EditProduct from '../pages/Products/Edit';

import ProductCategories from '../pages/Products/Categories';
import StoreProductCategories from '../pages/Products/Categories/Store';
import EditProductCategories from '../pages/Products/Categories/Edit';

import ProductMenu from '../pages/Products/Menu';
import ProductComplements from '../pages/Products/Complements';
import CreateProductComplements from '../pages/Products/Complements/Create';
import EditComplements from '../pages/Products/Complements/Edit';
import OrderProducts from '../pages/Products/OrderProducts';
import OrderProductsCategoryItem from '../pages/Products/OrderProducts/CategoryItem';
import OrderProductCategory from '../pages/Products/OrderProductCategory';

import ComplementsCategories from '../pages/Products/ComplementsCategories';
import CreateComplementsCategories from '../pages/Products/ComplementsCategories/Create';
import EditComplementsCategories from '../pages/Products/ComplementsCategories/Edit';

import Orders from '../pages/Orders';
import Operation from '../pages/Operation';
import Kds from '~/pages/Kds';

import Transfers from '../pages/Transfers';

import CreateTables from '~/pages/RegistersPage/Tables/Create';
import EditTables from '~/pages/RegistersPage/Tables/Edit';
import ViewTables from '~/pages/RegistersPage/Tables/View';
import ViewBills from '~/pages/Bills/View';
import CloseSolicitation from '../pages/Bills/CloseSolicitation';

import PaymentForm from '../pages/PaymentForm';
import StorePayment from '../pages/PaymentForm/StorePayment';

import PrintReportsProducts from '../pages/Reports/PrintReportsProducts';
import ReportsPrincipal from '../pages/Reports/ReportsPrincipal';
import ReportsRate from '../pages/Reports/ReportsRate';

import ReportsNew from '../pages/Reports/ReportsNew';
import ReportOpeningBox from '~/pages/Reports/ReportOpeningBox';

import TransferInputs from '~/pages/FiscalPage/TransferInputs';
import PrintNFePage from '../pages/PrintNFePage';

import CreateWaiter from '~/pages/RegistersPage/Waiter/Create';
import EditWaiter from '~/pages/RegistersPage/Waiter/Edit';

import TableSessions from '../pages/TableSessions';

import Settings from '../pages/Settings';
import EditPrinter from '../pages/Settings/EditPrinter';
import Inputs from '../pages/Inputs';

import Banks from '~/pages/Financial/Banks';

import Invoices from '~/pages/Financial/Invoices';

import Suppliers from '~/pages/Financial/Suppliers';
import PaymentsMethods from '~/pages/Financial/PaymentMethods';
import CostCenters from '~/pages/Financial/CostCenters';
import Transactions from '~/pages/Financial/Transactions';
import ReportsClient from '~/pages/Reports/ReportsClient';

import DeliveryConfig from '../pages/Delivery/Config';
import DeliveryAddresses from '../pages/Delivery/Addresses';
import Scheduling from '~/pages/Delivery/Scheduling';

import Tickets from '../pages/Delivery/Coupons';
import EditTicket from '../pages/Delivery/Coupons/Edit';
import NewTicket from '../pages/Delivery/Coupons/Store';
import DeliveryOrdersPage from '~/pages/Delivery/DeliveryOrdersPage';
import OpeningHours from '~/pages/Delivery/OpeningHours';
import Motoboy from '~/pages/Motoboys';
import EditMotoboy from '~/pages/Motoboys/Edit';
import ReportsExpenses from '~/pages/Reports/ReportsExpenses';

import Dre from '~/pages/Financial/DRE';
import Invoicing from '~/pages/Reports/ReportsInvoicing';

import { ServiceTax } from '~/pages/Reports/ServiceTaxReports';
import FiscalGroups from '~/pages/FiscalPage/FiscalGroups';
import IfoodRelation from '~/pages/IfoodRelation';
import Kitchen from '~/pages/Kds/Kitchen';

import Dfc from '~/pages/Financial/DFC';
import Help from '~/pages/Help';
import ViewDeliveryBills from '~/pages/DeliveryBills/View';
import FiscalRelation from '~/pages/FiscalPage/FiscalRelation';
import RegistersPage from '~/pages/RegistersPage';
import CashManagementPage from '~/pages/Financial/CashManagementPage';
import FiscalPage from '~/pages/FiscalPage';
import ReportsProductsPage from '~/pages/Reports/ReportsProductsPage';
import CreditRegister from '~/pages/Financial/CreditRegister';
import NotaAvulsa from '~/pages/FiscalPage/NotaAvulsa';
import NfeManifestInfo from '~/pages/FiscalPage/NfeManifestInfo';
import { SearchPage } from '~/pages/SearchPage';
import { menuItems } from '~/utils/menuItems';
import { useAuth } from '~/context/AuthContext';
import { BalconyPage } from '~/pages/BalconyPage';
import { BalancePage } from '~/pages/BalancePage'
import Markup from '~/pages/Markup';

export default function Routes() {
  const { user } = useAuth();
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      {/* <Route path="/dashboard" component={Dashboard} isPrivate /> */}

      <Route path="/profile/info" component={ProfilePage} isPrivate />
      <Route path="/profile/tasks" exact component={ProfilePage} isPrivate />
      <Route
        path="/profile/user-profile"
        exact
        component={ProfilePage}
        isPrivate
      />

      <Route path="/products/menu" component={ProductMenu} isPrivate />

      <Route path="/register/stock" component={Inputs} isPrivate />
      <Route path="/register/markup" component={Markup} isPrivate />

      <Route
        path="/products/categories/new"
        component={StoreProductCategories}
        isPrivate
      />
      <Route
        path="/products/categories/edit"
        component={EditProductCategories}
        isPrivate
      />
      <Route
        path="/products/categories"
        component={ProductCategories}
        isPrivate
      />
      <Route
        path="/products/new/:category_id"
        component={CreateProduct}
        isPrivate
      />
      <Route path="/products/edit" component={EditProduct} isPrivate />

      <Route path="/products/ifood" component={IfoodRelation} isPrivate />
      <Route path="/products/fiscal" component={FiscalRelation} isPrivate />

      <Route
        path="/products/complements/new"
        component={CreateProductComplements}
        isPrivate
      />
      <Route
        path="/products/complements/edit"
        component={EditComplements}
        isPrivate
      />
      <Route
        path="/products/complements"
        component={ProductComplements}
        isPrivate
      />

      <Route
        path="/products/complement-categories/new"
        component={CreateComplementsCategories}
        isPrivate
      />
      <Route
        path="/products/complement-categories/edit"
        component={EditComplementsCategories}
        isPrivate
      />
      <Route
        path="/products/complement-categories"
        component={ComplementsCategories}
        isPrivate
      />
      <Route
        path="/products/order-products"
        exact
        component={OrderProducts}
        isPrivate
      />
      <Route
        path="/products/order-product-categories"
        component={OrderProductCategory}
        isPrivate
      />
      <Route
        path="/products/order-products/category"
        component={OrderProductsCategoryItem}
        isPrivate
      />

      <Route
        path="/operation/fiscal/manifest/info"
        component={NfeManifestInfo}
        isPrivate
      />

      <Route
        path="/operation/fiscal/manifest"
        component={FiscalPage}
        isPrivate
      />

      <Route
        path="/operation/fiscal/entries"
        component={FiscalPage}
        isPrivate
      />
      <Route
        path="/operation/fiscal/nfe-avulsa"
        component={NotaAvulsa}
        isPrivate
      />
      <Route
        path="/operation/fiscal/returned"
        component={FiscalPage}
        isPrivate
      />
      <Route path="/operation/fiscal/issued" component={FiscalPage} isPrivate />
      <Route path="/print-nfce/:ref" component={PrintNFePage} isPrivate />
      <Route
        path="/operation/transfer-inputs"
        component={TransferInputs}
        isPrivate
      />

      <Route path="/financial/banks" component={Banks} isPrivate />
      <Route
        path="/operation/audit-box"
        component={CashManagementPage}
        isPrivate
      />
      <Route path="/register/suppliers" component={Suppliers} isPrivate />
      <Route
        path="/financial/payments-methods"
        component={PaymentsMethods}
        isPrivate
      />
      <Route path="/financial/cost-center" component={CostCenters} isPrivate />
      <Route
        path="/operation/transactions"
        component={Transactions}
        isPrivate
      />

      <Route
        path="/operation/credit-register"
        component={CreditRegister}
        isPrivate
      />

      <Route path="/reports/dre" component={Dre} isPrivate />
      <Route path="/reports/dfc" component={Dfc} isPrivate />

      <Route path="/financial/invoices" component={Invoices} isPrivate />

      <Route path="/register/tables/new" component={CreateTables} isPrivate />
      <Route path="/register/tables/edit" component={EditTables} isPrivate />
      <Route path="/register/tables/view" component={ViewTables} isPrivate />

      <Route
        path="/bills/close-solicitation"
        component={CloseSolicitation}
        isPrivate
      />
      <Route path="/operation/bills" component={ViewBills} isPrivate />
      <Route path="/delivery/bills" component={ViewDeliveryBills} isPrivate />

      <Route path="/orders" component={Orders} isPrivate />
      <Route path="/operation" exact component={Operation} isPrivate />
      <Route path="/balcony" exact component={BalconyPage} isPrivate />
      <Route path="/balance" exact component={BalancePage} isPrivate />
      <Route path="/operation/kds" component={Kds} isPrivate />
      <Route path="/kitchen" component={Kitchen} isPrivate />

      <Route path="/operation/transfers" component={Transfers} isPrivate />

      <Route path="/box/payment/new" component={StorePayment} isPrivate />

      <Route
        path="/operation/opening-box"
        component={CashManagementPage}
        exact
        isPrivate
      />
      <Route path="/financial/payment" component={PaymentForm} isPrivate />
      <Route
        path="/operation/cashier"
        component={CashManagementPage}
        isPrivate
      />

      <Route path="/reports-old" component={ReportsPrincipal} isPrivate />

      <Route
        path="/reports/products/products"
        component={ReportsProductsPage}
        isPrivate
      />
      <Route path="/reports/clients" component={ReportsClient} isPrivate />

      <Route
        path="/reports/print"
        exact
        component={PrintReportsProducts}
        isPrivate
      />

      <Route
        path="/reports/opening-box"
        component={ReportOpeningBox}
        isPrivate
        exact
      />

      <Route path="/reports/rate" exact component={ReportsRate} isPrivate />
      <Route
        path="/reports/expenses"
        exact
        component={ReportsExpenses}
        isPrivate
      />
      <Route
        path="/reports/products/canceled-products"
        exact
        component={ReportsProductsPage}
        isPrivate
      />
      <Route path="/reports/invoicing" exact component={Invoicing} isPrivate />
      <Route
        path="/reports/products/discounts"
        exact
        component={ReportsProductsPage}
        isPrivate
      />

      <Route path="/reports/service-tax" component={ServiceTax} isPrivate />
      <Route path="/reports" component={ReportsNew} isPrivate />

      <Route path="/products" exact component={Products} isPrivate />

      <Route path="/help" component={Help} isPrivate />

      <Route path="/waiters/new" exact component={CreateWaiter} isPrivate />
      <Route
        path="/register/waiters/edit"
        exact
        component={EditWaiter}
        isPrivate
      />
      <Route
        path="/register/waiters"
        exact
        component={RegistersPage}
        isPrivate
      />
      <Route path="/register/users" exact component={RegistersPage} isPrivate />

      <Route path="/register" exact component={RegistersPage} isPrivate />
      <Route
        path="/register/tables"
        exact
        component={RegistersPage}
        isPrivate
      />
      <Route
        path="/register/stone-devices"
        exact
        component={RegistersPage}
        isPrivate
      />
      <Route path="/register/balances" exact component={RegistersPage} isPrivate />
      <Route path="/table-sessions" component={TableSessions} isPrivate />
      <Route path="/settings/printer" component={EditPrinter} isPrivate />
      <Route path="/settings" component={Settings} isPrivate />

      <Route
        path="/register/fiscal-groups"
        component={FiscalGroups}
        isPrivate
      />

      <Route
        path="/delivery/orders"
        exact
        component={DeliveryOrdersPage}
        isPrivate
      />
      <Route
        path="/delivery/config"
        exact
        component={DeliveryConfig}
        isPrivate
      />
      <Route path="/delivery/motoboys" exact component={Motoboy} isPrivate />
      <Route
        path="/delivery/opening-hours"
        exact
        component={OpeningHours}
        isPrivate
      />
      <Route
        path="/delivery/motoboys/edit"
        exact
        component={EditMotoboy}
        isPrivate
      />

      <Route
        path="/delivery/addresses"
        exact
        component={DeliveryAddresses}
        isPrivate
      />

      <Route path="/delivery/coupons/new" component={NewTicket} isPrivate />
      <Route path="/delivery/coupons/edit" component={EditTicket} isPrivate />
      <Route path="/delivery/coupons" component={Tickets} isPrivate />
      <Route
        path="/delivery/scheduling"
        exact
        component={Scheduling}
        isPrivate
      />
      <Route path="/search" component={() => <SearchPage menuItems={menuItems} user={user} />} isPrivate />
      {/* <Route path="/homepage" component={Homepage} isPrivate /> */}

    </Switch>
  );
}