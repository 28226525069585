import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { toast } from 'react-hot-toast';
import jwtDecode from 'jwt-decode';
import api from '~/services/api';
import history from '~/services/history';

import FCMManager from '~/FCMManager';
import WebsocketManager from '~/WebsocketManager';

import { differenceInHours, subHours } from 'date-fns';
import { useLDClient } from 'launchdarkly-react-client-sdk';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@gddashboard:token');
    const user = localStorage.getItem('@gddashboard:user');
    if (token && user) {
      if (token !== null) {
        try {
          const { exp } = jwtDecode(token);
          const expirationTime = exp * 1000;
          if (Date.now() >= expirationTime) {
            localStorage.removeItem('@gddashboard:token');
            localStorage.removeItem('@gddashboard:user');

            return { token: null, user: null };
          }
        } catch (err) {
          console.log('invalid Token');
        }
      }
      api.defaults.headers.Authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return { token: null, user: null };
  });

  // Força o widget/chat do hubspot ser resetado
  const resetHubspotWidget = useRef(false);

  const [loading, setLoading] = useState(false);
  const [websocketManager, setWebsocketManager] = useState(null);
  const [fcmManager, setFcmManager] = useState(null);

  const signOut = useCallback(() => {
    localStorage.removeItem('@gddashboard:token');
    localStorage.removeItem('@gddashboard:user');
    localStorage.removeItem('@gddashboard:ifood_complements');
    localStorage.removeItem('@gddashboard:ifood_products');
    localStorage.removeItem('@gddashboard:ifood_menu');
    localStorage.removeItem('@gddashboard:ifood_restaurants');

    localStorage.removeItem('@gddashboard:formNotaAvulsa');
    localStorage.removeItem('@gddashboard:itensNotaAvulsa');
    localStorage.removeItem('@gddashboard:volumesNotaAvulsa');
    localStorage.removeItem('@gddashboard:ifood_restaurants');

    // localStorage.removeItem('@gddashboard:userAlreadyRate');

    // localStorage.removeItem('@gddashboard:modalNps');

    // Removendo o token do hubspot em cache
    localStorage.removeItem('@gddashboard:hubspot');

    resetHubspotWidget.current = true;

    window.hsConversationsSettings = {
      loadImmediately: false,
    };

    setModalNpsCloseTime(subHours(new Date(), 7));
    setUserAlreadyRate(false);

    setData({ user: null, token: null });

    history.push('/');
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    setLoading(true);
    try {
      const response = await api.post('public/sessions/restaurants', {
        email,
        password,
      });

      const { token, user } = response.data;

      localStorage.setItem('@gddashboard:token', token);
      localStorage.setItem('@gddashboard:user', JSON.stringify(user));

      api.defaults.headers.Authorization = `Bearer ${token}`;

      window.hsConversationsSettings = {
        loadImmediately: false,
      };

      resetHubspotWidget.current = true;

      setData({ token, user });
    } catch (err) {
      toast.error('Erro ao realizar login, verifique seus dados!');
    }

    setLoading(false);
  }, []);

  const getMenewProducts = useCallback(async () => {
    if (data?.user?.has_menew) {
      try {
        const response = await api.get('restaurants/menew/products');
        localStorage.setItem(
          '@gddashboard:menewProducts',
          JSON.stringify(response.data)
        );
        setMenewProducts(response.data);

        if (Array.isArray(response.data)) {
          toast.success('Produtos Menew atualizados com sucesso!');
        } else {
          toast.error(
            'Houve algum erro! Produtos Menew não foram atualizados.'
          );
        }
      } catch (err) {
        toast.error('Erro ao carregar produtos menew! Tente novamente');
      }
    }
  }, [data?.user]);

  const [menewProducts, setMenewProducts] = useState(() => {
    const products = localStorage.getItem('@gddashboard:menewProducts');
    if (!products) {
      getMenewProducts();
    }
    return JSON.parse(products);
  });

  const [paymentMethodsState, setPaymentMethods] = useState([]);

  const getPaymentMethods = useCallback(async () => {
    const response = await api.get('restaurants/payment-methods');

    if (response.data) {
      setPaymentMethods(response.data.payment_methods);
    }
  }, []);

  useEffect(() => {
    if (data?.token) {
      getPaymentMethods();
    }
  }, [getPaymentMethods, data?.token]);

  const [generalNotifications, setGeneralNotifications] = useState(() => {
    const notification = localStorage.getItem(
      '@gddashboard:generalNotifications'
    );

    if (notification) {
      return JSON.parse(notification);
    }

    return [];
  });

  const [notificationsOpen, setNotificationsOpen] = useState(() => {
    const notification = localStorage.getItem('@gddashboard:notificationsOpen');

    if (notification === 'true' || notification === true) {
      return true;
    } else {
      return false;
    }
  });

  const [restaurantDiscountObs, setDiscountObs] = useState(() => {
    const obs = localStorage.getItem('@gddashboard:discountObs');

    if (obs) {
      return JSON.parse(obs);
    }

    return [];
  });

  const getDiscountObs = useCallback(async () => {
    try {
      const response = await api.get('restaurants/discount-obs');
      localStorage.setItem(
        '@gddashboard:discountObs',
        JSON.stringify(response.data)
      );
      setDiscountObs(response.data);
    } catch (err) {
      console.log('erro ao pegar descontos obs', err);
    }
  }, []);

  useEffect(() => {
    getDiscountObs();
  }, [getDiscountObs]);

  const updateDiscountObs = useCallback(async (obs) => {
    try {
      const response = await api.post('restaurants/discount-obs', { obs });
      localStorage.setItem(
        '@gddashboard:discountObs',
        JSON.stringify(response.data)
      );
      setDiscountObs(response.data);
    } catch (err) {
      console.log('erro ao atualizar/inserir descontos obs', err);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:discountObs',
      JSON.stringify(restaurantDiscountObs)
    );
  }, [restaurantDiscountObs]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:generalNotifications',
      JSON.stringify(generalNotifications)
    );
  }, [generalNotifications]);

  useEffect(() => {
    localStorage.setItem('@gddashboard:notificationsOpen', notificationsOpen);
  }, [notificationsOpen]);

  const removediscountObs = useCallback(async (discount_id) => {
    try {
      const response = await api.post(
        `restaurants/remove-discount-obs/${discount_id}`
      );

      setDiscountObs((state) => {
        const filtered = state.filter((item) => item.id !== discount_id);

        return filtered;
      });

      toast.success('Justicativa removida');
    } catch (e) {
      toast.error('Erro ao remover justificativa');
    }
  }, []);

  const [userAccess, setUserAccess] = useState(() => {
    const userAccessStorage = localStorage.getItem('@gddashboard:userAccess');

    if (userAccessStorage) {
      return userAccessStorage;
    }

    return '';
  });

  const [modalOperationMessageWhatsapp, setModalOperationMessageWhatsapp] =
    useState(() => {
      const modalOperationMessageWhatsappStorage = localStorage.getItem(
        '@gddashboard:modalOperationMessageWhatsapp'
      );

      if (modalOperationMessageWhatsappStorage) {
        return modalOperationMessageWhatsappStorage;
      }

      return true;
    });

  const [
    modalOperationMessageWhatsappOnline,
    setModalOperationMessageWhatsappOnline,
  ] = useState(() => {
    const modalOperationMessageWhatsappOnlineStorage = localStorage.getItem(
      '@gddashboard:modalOperationMessageWhatsappOnline'
    );

    if (modalOperationMessageWhatsappOnlineStorage) {
      return modalOperationMessageWhatsappOnlineStorage;
    }

    return true;
  });

  const [userAlreadyRate, setUserAlreadyRate] = useState(() => {
    const userAlreadyRateStorage = localStorage.getItem(
      '@gddashboard:userAlreadyRate'
    );

    if (userAlreadyRateStorage) {
      return userAlreadyRateStorage;
    }

    return false;
  });

  const [modalOperationClubSale, setModalOperationClubSale] = useState(() => {
    const modalOperationClubSaleStorage = localStorage.getItem(
      '@gddashboard:modalOperationClubSale'
    );

    if (modalOperationClubSaleStorage) {
      return modalOperationClubSaleStorage;
    }

    return true;
  });

  // const [modalNps, setModalNps] = useState(
  //   () => {
  //     const modalNpsStorage = localStorage.getItem(
  //       '@gddashboard:modalNps'
  //     );

  //     if (modalNpsStorage) {
  //       return modalNpsStorage;
  //     }

  //    const hundredTime= response.data.hundred_sessions_date || new Date()

  //     if(differenceInMonths(new Date(), hundredTime) > 2){
  //       setModalNps(true)
  //     }else{
  //       setModalNps(false)
  //     }

  //   }
  // );

  const [modalNpsCloseTime, setModalNpsCloseTime] = useState(() => {
    const modalNpsCloseTimeStorage = localStorage.getItem(
      '@gddashboard:modalNpsCloseTime'
    );

    if (modalNpsCloseTimeStorage) {
      return modalNpsCloseTimeStorage;
    }

    return subHours(new Date(), 7);
  });

  const [toastUpdateSystemMessage4, setToastUpdateSystemMessage4] = useState(
    () => {
      const toastUpdateSystemMessageStorage4 = localStorage.getItem(
        '@gddashboard:toastUpdateSystemMessage4'
      );

      if (toastUpdateSystemMessageStorage4) {
        return toastUpdateSystemMessageStorage4;
      }

      return true;
    }
  );

  const [toastUpdateSystemMessage3, setToastUpdateSystemMessage3] = useState(
    () => {
      const toastUpdateSystemMessageStorage3 = localStorage.getItem(
        '@gddashboard:toastUpdateSystemMessage3'
      );

      if (toastUpdateSystemMessageStorage3) {
        return toastUpdateSystemMessageStorage3;
      }

      return true;
    }
  );

  const updateUser = useCallback(
    async (updateData) => {
      try {
        setLoading(true);
        const {
          name,
          fantasy_name,
          avatar_id,
          phone,
          adress,
          has_service_tax,
          service_tax,
          is_printed_on_web,
          auto_print_orders,
          auto_print_delivery,
          print_bills_on_web,
          print_separate_itens,
          print_canceled,
          print_transfer,
          has_nfce,
          nfce_token,
          cnpj,
          instagram,
          order_cancel_password,
          order_transfer_password,
          delivery_info,
          table_type,
          print_balcony_default,
          ask_waiter_key,
          greeting_message,
          audit_cashier,
          ifood_auto_accept,
          enable_menu,
          is_order_scheduling_active,
          financial_email,
          pixel_id,
          pixel_id2,
          use_cashier_by_user,
          default_cashier_user_id,
          print_complement_category_title,
          can_cashier_verify_sessions,
          print_bills_delivery_on_web,
          credit_register_enabled,
          waiters_can_close_sessions,
          enable_translations,
          credit_register_with_nfce,
          meta_access_token,
          meta_access_token2,
          print_baskets_in_kds,
          ...rest
        } = updateData;

        const profile = {
          name,
          fantasy_name,
          avatar_id,
          phone,
          adress,
          is_printed_on_web,
          print_separate_itens,
          auto_print_orders,
          auto_print_delivery,
          print_bills_on_web,
          print_canceled,
          print_transfer,
          has_nfce,
          nfce_token,
          cnpj,
          instagram,
          has_service_tax,
          service_tax,
          order_cancel_password,
          order_transfer_password,
          delivery_info,
          table_type,
          print_balcony_default,
          ask_waiter_key,
          greeting_message,
          audit_cashier,
          ifood_auto_accept,
          enable_menu,
          is_order_scheduling_active,
          financial_email,
          pixel_id,
          pixel_id2,
          use_cashier_by_user,
          default_cashier_user_id,
          print_complement_category_title,
          print_bills_delivery_on_web,
          can_cashier_verify_sessions,
          credit_register_enabled,
          waiters_can_close_sessions,
          enable_translations,
          credit_register_with_nfce,
          meta_access_token,
          meta_access_token2,
          print_baskets_in_kds,
          ...(rest.oldPassword ? rest : {}),
        };
        if (avatar_id) {
          profile.avatar_id = Number(avatar_id);
        }

        const response = await api.put('restaurants', profile);

        const profileUpdated = {
          ...response.data,
        };

        if (adress && adress.id) {
          const updatedAdress = await api.put(`/restaurants/address`, {
            street: adress?.street,
            number: Number(adress?.number),
            complement: adress?.complement,
            neighborhood: adress?.neighborhood,
            state: adress?.state,
            city: adress?.city,
            zip_code: adress.zip_code,
            inscricao_estadual: adress.inscricao_estadual,
          });
          profileUpdated.adress = updatedAdress.data;
        }
        if (adress && !adress.id) {
          const updatedAdress = await api.post(`/restaurants/address`, {
            street: adress?.street,
            number: Number(adress?.number),
            neighborhood: adress?.neighborhood,
            complement: adress?.complement,
            state: adress?.state,
            city: adress?.city,
            zip_code: adress.zip_code,
          });
          profileUpdated.adress = updatedAdress.data;
        }

        profileUpdated.print_complement_category_title =
          print_complement_category_title;
        profileUpdated.credit_register_enabled = credit_register_enabled;

        setData((oldState) => {
          return {
            token: data.token,
            user: { ...oldState.user, ...profileUpdated },
          };
        });

        toast.success('Perfil atualizado com Sucesso!');
        setLoading(false);
      } catch (err) {
        if (
          err.response.data.errorType === 'cannot_change_use_cashier_by_user'
        ) {
          toast.error(
            'Ainda existe(m) caixa(s) aberto(s), favor fechar para habilitar/ desabilitar o vincular caixa por usuário'
          );
        } else if (
          err.response.data.errorType === 'must_finish_credit_registers'
        ) {
          toast.error(
            'Existem comandas com pagamentos a prazo e outros métodos juntos a serem quitadas, favor verificar antes de alterar a opção de emissão de NF.'
          );
        } else {
          toast.error('Erro ao atualizar perfil, confira seus dados!');
        }

        setLoading(false);
      }
    },
    [setData, data.token]
  );

  const changeRestaurantStatus = useCallback(
    async (status) => {
      try {
        const response = await api.put('restaurants/status', status);

        const deliveryInfo = {
          ...data.user.delivery_info,
          ...response.data.delivery_info,
        };

        const user = {
          ...data.user,
          ifood_open: response.data.ifood_open,
          is_active: response.data.is_active,
          opened: response.data.opened,
          delivery_info: deliveryInfo,
        };

        setData({
          token: data.token,
          user,
        });

        if (user.opened) {
          toast.success('Restaurante aberto com sucesso', { autoClose: 2000 });
        } else {
          toast.success('Restaurante fechado com sucesso', { autoClose: 2000 });
        }

        return user;
      } catch (e) {
        if (data.user.opened) {
          toast.error('Falha ao fechar o restaurante!');
        } else {
          toast.error('Falha ao abrir o restaurante!');
        }

        return data.user;
      }
    },
    [data.user, setData, data.token]
  );

  const getProfileData = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/show');

      if (response.data.is_user_active === false) {
        signOut();
      } else {
        setData({
          token: data.token,
          user: {
            ...response.data,
            club_key: data.user.club_key,
          },
        });
      }

      // const hundredTime= response.data.hundred_sessions_date || new Date()

      // if(differenceInMonths(new Date(), hundredTime) > 2){
      //   setModalNps(true)
      // }else{
      //   setModalNps(false)
      // }
    } catch (err) {
      toast.error('Erro ao atualizar informações deste restaurante');
    }
  }, [setData, data.token, signOut]);

  const updateToken = useCallback(
    async (updateData) => {
      try {
        const {
          x_picpay_token,
          x_seller_token,
          paytime_seller_id,
          withdrawal_allowed,
        } = updateData;

        const profile = {
          x_picpay_token,
          x_seller_token,
          paytime_seller_id,
          withdrawal_allowed,
        };

        const response = await api.put('restaurants', profile);

        const profileUpdated = {
          ...response.data,
        };
        setData({
          token: data.token,
          user: profileUpdated,
        });

        toast.success('Tokens atualizados com Sucesso!');
      } catch (err) {
        toast.error('Erro ao atualizar Tokens, confira seus dados!');
      }
    },
    [data.token]
  );

  const updateDeliveryStatus = useCallback(
    async (status) => {
      try {
        const response = await api.put('restaurants/status', status);

        const deliveryInfo = {
          ...data.user.delivery_info,
          ...response.data.delivery_info,
        };

        const user = {
          ...data.user,
          ifood_open: response.data.ifood_open,
          is_active: response.data.is_active,
          opened: response.data.opened,
          delivery_info: deliveryInfo,
        };

        setData({
          token: data.token,
          user,
        });

        if (user.delivery_info.is_delivery_active) {
          toast.success('Delivery ativado com sucesso!');
        } else {
          toast.success('Delivery pausado com sucesso!');
        }

        return response.data;
      } catch (err) {
        toast.error('Erro ao atualizar, tente novamente!');
      }

      return data.user;
    },
    [data.user, setData, data.token]
  );

  const updateWithdrawalStatus = useCallback(
    async (status) => {
      try {
        const response = await api.put('restaurants/status', status);

        const deliveryInfo = {
          ...data.user.delivery_info,
          ...response.data.delivery_info,
        };

        const user = {
          ...data.user,
          ifood_open: response.data.ifood_open,
          is_active: response.data.is_active,
          opened: response.data.opened,
          delivery_info: deliveryInfo,
        };

        setData({
          token: data.token,
          user,
        });

        if (user.delivery_info.is_withdrawal_active) {
          toast.success('Retirada ativada com sucesso!');
        } else {
          toast.success('Retirada pausada com sucesso!');
        }

        return response.data;
      } catch (err) {
        toast.error('Erro ao atualizar, tente novamente!');
      }

      return data.user;
    },
    [data.user, setData, data.token]
  );

  const updateSMSInfo = useCallback(
    async (updateData) => {
      try {
        const {
          has_sms_service,
          is_sms_service_optional,
          client_pay_sms,
          whatsapp,
        } = updateData;

        const profile = {
          has_sms_service,
          is_sms_service_optional,
          client_pay_sms,
          whatsapp,
        };
        const response = await api.put('restaurants', profile);

        const profileUpdated = {
          ...response.data,
        };

        setData({
          token: data.token,
          user: profileUpdated,
        });

        toast.success('Configurações atualizadas com sucesso!');
      } catch (err) {
        toast.error('Erro ao atualizar configurações, confira seus dados!');
      }
    },
    [setData, data.token]
  );

  const updateHubspotCrendentials = useCallback(async () => {
    const email = data.user?.restaurant_email;

    if (email) {
      let hubspot = JSON.parse(
        localStorage.getItem('@gddashboard:hubspot') || 'null'
      );

      // Token expira em 12 horas, usando 11 horas para evitar período de desconexão
      const _11hours = 11 * 3600 * 1000;
      const resetToken = hubspot
        ? hubspot.timestamp - Date.now() >= _11hours
        : true;

      if (resetToken) {
        try {
          // Get from backend
          const response = await api.post('restaurants/hubspot/auth', {
            name: data.user.fantasy_name,
            email,
          });

          const { token } = response.data;

          hubspot = {
            token: token,
            timestamp: Date.now(),
          };

          localStorage.setItem('@gddashboard:hubspot', JSON.stringify(hubspot));
        } catch (error) {
          console.log('Hubspot Token Error', error);
          return;
        }
      }

      window.hsConversationsSettings = {
        identificationEmail: email,
        identificationToken: hubspot.token,
      };
    }

    if (resetHubspotWidget.current) {
      resetHubspotWidget.current = false;

      // Removendo cookies do hubspot
      for (const name of ['hubspotutk', '__hssrc', '__hssc', '__hstc']) {
        document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      }

      if (window.HubSpotConversations) {
        window.HubSpotConversations.clear({ resetWidget: true });
      }
    }

    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.load();
    } else {
      window.hsConversationsOnReady = [
        () => {
          window.HubSpotConversations.widget.load();
        },
      ];
    }
  }, [data.user?.restaurant_email, data.user?.fantasy_name]);

  useEffect(() => {
    updateHubspotCrendentials();
  }, [updateHubspotCrendentials]);

  const [waiterId, setWaiterId] = useState(() => {
    const waiterIdStorage = localStorage.getItem('@garcomdigital:waiterId');

    if (waiterIdStorage) {
      return waiterIdStorage;
    }

    return '';
  });

  const [buyerCreatedByWaiter, setBuyerCreatedByWaiter] = useState(() => {
    const buyerCreatedByWaiterStorage = localStorage.getItem(
      '@waitermanager:buyerCreatedByWaiter'
    );

    if (buyerCreatedByWaiterStorage) {
      return JSON.parse(buyerCreatedByWaiterStorage);
    }

    return '';
  });

  const [buyerSelectedByWaiter, setBuyerSelectedByWaiter] = useState(() => {
    const buyerSelectedByWaiterStorage = localStorage.getItem(
      '@waitermanager:buyerSelectedByWaiter'
    );

    if (buyerSelectedByWaiterStorage) {
      return JSON.parse(buyerSelectedByWaiterStorage);
    }

    return '';
  });

  const [buyersCreatedByWaiter, setBuyersCreatedByWaiter] = useState(() => {
    const buyersCreatedByWaiterStorage = localStorage.getItem(
      '@waitermanager:buyersCreatedByWaiter'
    );

    if (buyersCreatedByWaiterStorage) {
      return JSON.parse(buyersCreatedByWaiterStorage);
    }

    return '';
  });

  const [modalLiveBanner, setModalLiveBanner] = useState(() => {
    const modalLiveBannerStorage = localStorage.getItem(
      '@gddashboard:modalMaquininha'
    );

    if (modalLiveBannerStorage) {
      return modalLiveBannerStorage;
    }

    return true;
  });

  const [modalBannerGetnetAlert, setModalBannerGetnetAlert] = useState(() => {
    const modalBannerGetnetAlertStorage = localStorage.getItem(
      '@gddashboard:modalBannerGetnetAlert'
    );

    if (modalBannerGetnetAlertStorage) {
      return modalBannerGetnetAlertStorage;
    }

    return true;
  });

  const [modalLiveBannerNew, setModalLiveBannerNew] = useState(() => {
    const modalLiveBannerStorageNew = localStorage.getItem(
      '@gddashboard:modalLiveBannerNew'
    );

    if (modalLiveBannerStorageNew) {
      return modalLiveBannerStorageNew;
    }

    return true;
  });

  const [popupIfood, setPopupIfood] = useState(() => {
    const popupIfoodStorage = localStorage.getItem('@gddashboard:popupIfood');

    if (popupIfoodStorage) {
      return popupIfoodStorage;
    }

    return true;
  });

  const [modalManagerArea, setModalManagerArea] = useState(() => {
    const modalManagerAreaStorage = JSON.parse(
      localStorage.getItem('@gddashboard:modalManagerArea')
    );

    if (modalManagerAreaStorage === null) {
      return true;
    }

    return modalManagerAreaStorage;
  });

  const [warningManagerArea, setWarningManagerArea] = useState(() => {
    const warningManagerAreaStorage = JSON.parse(
      localStorage.getItem('@gddashboard:warningManagerArea')
    );

    if (warningManagerAreaStorage === null) {
      return true;
    }

    return warningManagerAreaStorage;
  });

  const [modalBannerDecret, setModalBannerDecret] = useState(() => {
    const modalBannerDecretStorage = localStorage.getItem(
      '@gddashboard:modalBannerDecret'
    );

    if (modalBannerDecretStorage) {
      return modalBannerDecretStorage;
    }

    return true;
  });

  //banner schedules

  //estado pra setar se o usuário cadastrou um horário
  const [hasAScheduleBeenConfigured, setHasAScheduleBeenConfigured] = useState(
    () => {
      const hasAScheduleBeenConfiguredStorage = localStorage.getItem(
        '@gddashboard:hasAScheduleBeenConfigured'
      );
      return hasAScheduleBeenConfiguredStorage === 'true';
    }
  );

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:hasAScheduleBeenConfigured',
      hasAScheduleBeenConfigured
    );
  }, [hasAScheduleBeenConfigured]);

  const getUser = useCallback(() => {
    if (data && data.user) {
      return data.user;
    }

    return null;
  }, [data]);

  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:buyerSelectedByWaiter',
      JSON.stringify(buyerSelectedByWaiter)
    );
  }, [buyerSelectedByWaiter]);

  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:buyerCreatedByWaiter',
      JSON.stringify(buyerCreatedByWaiter)
    );
  }, [buyerCreatedByWaiter]);

  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:buyersCreatedByWaiter',
      JSON.stringify(buyersCreatedByWaiter)
    );
  }, [buyersCreatedByWaiter]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:cart', waiterId);
  }, [waiterId]);

  useEffect(() => {
    if (data.user) {
      getProfileData();
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('@gddashboard:token', data.token);

    if (data.token && data.token !== 'null') {
      const manager = new WebsocketManager(data.token);

      manager.addMessageCallback(-1, (data) => {
        console.log('AuthContext: socket new data');
      });

      setWebsocketManager(manager);

      setFcmManager(new FCMManager());
    } else if (websocketManager) {
      websocketManager.close();
      setWebsocketManager(null);
    }
  }, [data.token]);

  useEffect(() => {
    if (websocketManager) {
      websocketManager.addMessageCallback(7, (dt) => {
        if (dt.type === 'sch:delivery-status') {
          const { is_delivery_active, is_withdrawal_active } = dt.item;

          const deliveryInfo = {
            ...data.user.delivery_info,
            is_delivery_active,
            is_withdrawal_active,
          };

          const user = {
            ...data.user,
            delivery_info: deliveryInfo,
          };

          setData({
            token: data.token,
            user,
          });
        } else if (dt.type === 'payment-methods-updated') {
          getPaymentMethods();
        }
      });
    }
  }, [websocketManager, setData, data.token, data.user]);

  useEffect(() => {
    localStorage.setItem('@gddashboard:userAccess', userAccess);
  }, [userAccess]);

  useEffect(() => {
    localStorage.setItem('@gddashboard:user', JSON.stringify(data.user));
  }, [data.user]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:modalOperationMessageWhatsapp',
      modalOperationMessageWhatsapp
    );
  }, [modalOperationMessageWhatsapp]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:modalOperationMessageWhatsappOnline',
      modalOperationMessageWhatsappOnline
    );
  }, [modalOperationMessageWhatsappOnline]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:modalOperationClubSale',
      modalOperationClubSale
    );
  }, [modalOperationClubSale]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:toastUpdateSystemMessage4',
      toastUpdateSystemMessage4
    );
  }, [toastUpdateSystemMessage4]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:toastUpdateSystemMessage3',
      toastUpdateSystemMessage3
    );
  }, [toastUpdateSystemMessage3]);

  // useEffect(() => {
  //   localStorage.setItem(
  //     '@gddashboard:modalNps',
  //     modalNps
  //   );
  // }, [modalNps]);

  useEffect(() => {
    localStorage.setItem('@gddashboard:modalNpsCloseTime', modalNpsCloseTime);
  }, [modalNpsCloseTime]);

  useEffect(() => {
    localStorage.setItem('@gddashboard:userAlreadyRate', userAlreadyRate);
  }, [userAlreadyRate]);

  useEffect(() => {
    localStorage.setItem('@gddashboard:modalMaquininha', modalLiveBanner);
  }, [modalLiveBanner]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:modalBannerGetnetAlert',
      modalBannerGetnetAlert
    );
  }, [modalBannerGetnetAlert]);

  useEffect(() => {
    localStorage.setItem('@gddashboard:modalLiveBannerNew', modalLiveBannerNew);
  }, [modalLiveBannerNew]);

  useEffect(() => {
    localStorage.setItem('@gddashboard:popupIfood', popupIfood);
  }, [popupIfood]);

  useEffect(() => {
    localStorage.setItem('@gddashboard:modalBannerDecret', modalBannerDecret);
  }, [modalBannerDecret]);

  // Launch darkly
  const LDClient = useLDClient();
  useEffect(() => {
    if (data.user) {
      LDClient.identify({
        kind: 'restaurante',
        key: data.user.name,
        Nome: data.user.fantasy_name,
        Acesso: data.user.access,
        Estado: data.user.adress?.state,
        'Apenas PDV': data.user.is_pdv,
      });
    }
  }, [data.user]);

  const value = useMemo(
    () => ({
      signIn,
      user: data.user,
      token: data.token,
      setData,
      signOut,
      updateUser,
      loading,
      changeRestaurantStatus,
      updateToken,
      updateSMSInfo,
      menewProducts,
      getMenewProducts,
      updateDiscountObs,
      getDiscountObs,
      restaurantDiscountObs,
      updateDeliveryStatus,
      userAccess,
      setUserAccess,
      getProfileData,
      updateWithdrawalStatus,
      waiterId,
      setWaiterId,
      websocketManager,
      fcmManager,
      toastUpdateSystemMessage4,
      setToastUpdateSystemMessage4,
      toastUpdateSystemMessage3,
      setToastUpdateSystemMessage3,
      removediscountObs,
      generalNotifications,
      setGeneralNotifications,
      notificationsOpen,
      setNotificationsOpen,
      // modalNps,
      // setModalNps,
      modalNpsCloseTime,
      setModalNpsCloseTime,
      userAlreadyRate,
      setUserAlreadyRate,
      modalOperationMessageWhatsapp,
      setModalOperationMessageWhatsapp,
      modalOperationMessageWhatsappOnline,
      setModalOperationMessageWhatsappOnline,
      buyerCreatedByWaiter,
      setBuyerCreatedByWaiter,
      buyerSelectedByWaiter,
      setBuyerSelectedByWaiter,
      buyersCreatedByWaiter,
      setBuyersCreatedByWaiter,
      modalOperationClubSale,
      setModalOperationClubSale,
      modalLiveBanner,
      setModalLiveBanner,
      modalLiveBannerNew,
      setModalLiveBannerNew,
      setPopupIfood,
      popupIfood,
      modalBannerGetnetAlert,
      setModalBannerGetnetAlert,
      modalBannerDecret,
      setModalBannerDecret,
      modalManagerArea,
      setModalManagerArea,
      warningManagerArea,
      setWarningManagerArea,
      getUser,
      setHasAScheduleBeenConfigured,
      hasAScheduleBeenConfigured,
      paymentMethodsState,
    }),
    [
      signIn,
      data.user,
      data.token,
      setData,
      signOut,
      updateUser,
      loading,
      changeRestaurantStatus,
      updateToken,
      updateSMSInfo,
      menewProducts,
      getMenewProducts,
      updateDiscountObs,
      getDiscountObs,
      restaurantDiscountObs,
      updateDeliveryStatus,
      userAccess,
      setUserAccess,
      updateWithdrawalStatus,
      getProfileData,
      waiterId,
      setWaiterId,
      websocketManager,
      fcmManager,
      toastUpdateSystemMessage4,
      setToastUpdateSystemMessage4,
      toastUpdateSystemMessage3,
      setToastUpdateSystemMessage3,
      removediscountObs,
      generalNotifications,
      setGeneralNotifications,
      notificationsOpen,
      setNotificationsOpen,
      // modalNps,
      // setModalNps,
      modalNpsCloseTime,
      setModalNpsCloseTime,
      userAlreadyRate,
      setUserAlreadyRate,
      modalOperationMessageWhatsapp,
      setModalOperationMessageWhatsapp,
      buyerCreatedByWaiter,
      setBuyerCreatedByWaiter,
      buyerSelectedByWaiter,
      setBuyerSelectedByWaiter,
      buyersCreatedByWaiter,
      setBuyersCreatedByWaiter,
      modalOperationMessageWhatsappOnline,
      setModalOperationMessageWhatsappOnline,
      modalOperationClubSale,
      setModalOperationClubSale,
      modalLiveBanner,
      setModalLiveBanner,
      modalLiveBannerNew,
      setModalLiveBannerNew,
      popupIfood,
      setPopupIfood,
      modalBannerDecret,
      setModalBannerDecret,
      modalManagerArea,
      setModalManagerArea,
      warningManagerArea,
      setWarningManagerArea,
      getUser,
      setHasAScheduleBeenConfigured,
      hasAScheduleBeenConfigured,
      paymentMethodsState,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { useAuth, AuthProvider };
