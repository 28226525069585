import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-hot-toast';

import { MdDelete, MdEdit } from 'react-icons/md';

import JSZip from 'jszip';
import FileSaver from 'file-saver';
import {
  Container,
  Header,
  StyledLink,
  TablesTable,
  ProductImage,
  Delete,
  Actions,
  StatusTag,
  AreaSwitcher,
} from './styles';

import QrCodePrint from './QrCodePrint';
import { Link } from 'react-router-dom';
// import Pagination from '~/components/Pagination';
import { useAuth } from '~/context/AuthContext';
import RegisterButton from '~/components/Buttons/RegisterButton';

import api from '~/services/api';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { Button, Tabs } from 'ui-kit-takeat';
import { FaDownload, FaPlus } from 'react-icons/fa';
import PersonalOutlineButton from '~/components/Buttons/PersonalOutlineButton';
import Switch from 'react-switch';

function Tables() {
  const [tables, setTables] = useState([]);
  const [tablesFiltered, setTablesFiltered] = useState([]);

  const { user } = useAuth();

  const [activeTab, setActiveTab] = useState(0);

  const [disabledCreate, setDisabledCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [canCreateBalcony, setCanCreateBalcony] = useState(false);
  const [requireTableNumber, setRequireTableNumber] = useState(true);

  const initialData = {
    require_table_number: requireTableNumber,
  };

  useEffect(() => {
    getRestaurantSettingsData()
      .then((response) => {
        setRequireTableNumber(response.data.require_table_number);
      }).catch((e) => {
        console.log('error', e)
      });
  }, [])

  const getRestaurantSettingsData = async () => {
    const response = await api.get('/restaurants/tables/command-table-settings/');
    return response;
  }

  const updateRestaurantSettingsData = async (data) => {
    const response = await api.put(
      '/restaurants/tables/command-table-settings/',
      { require_table_number: data }
    );
    return response;
  }

  const handleUpdateRestaurantSettingsData = async (value) => {
    await updateRestaurantSettingsData(value)
      .then(() => {
        toast.success('Configurações atualizadas com sucesso');
        setRequireTableNumber(value);
      }).catch((e) => {
        toast.error('Erro ao atualizar configurações');
        console.log('error', e)
      });
  }

  // const [totalItems, setTotalItems] = useState(0);

  const getTables = useCallback(async () => {
    const response = await api.get('/restaurants/tables');
    setTables(response.data);
    setTablesFiltered(
      response.data.filter((table) =>
        ['table', 'balcony', 'delivery', 'prepaid', 'menu'].includes(
          table.table_type
        )
      )
    );

    const canCreateBalcony = response.data.filter(
      (table) => table.table_type === 'balcony'
    );
    if (canCreateBalcony.length === 0 && user?.has_balcony) {
      setCanCreateBalcony(true);
    }

    if (user.table_limit_type === 'only-balcony') {
      setDisabledCreate(true);
    } else if (user.table_limit_type === 'limited-tables') {
      const tables = response.data.filter(
        (table) => table.table_type === 'table'
      ).length;
      if (tables >= parseFloat(user.table_limit)) {
        setDisabledCreate(true);
      }
    }
    // setTotalItems(response.data.count);
  }, [user]);

  useEffect(() => {
    try {
      getTables();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getTables]);

  function changeFilter(tab) {
    switch (tab) {
      case 0:
        setTablesFiltered(
          tables.filter((table) =>
            ['table', 'balcony', 'delivery', 'prepaid', 'menu'].includes(
              table.table_type
            )
          )
        );
        break;
      case 1:
        setTablesFiltered(
          tables.filter((table) => ['command'].includes(table.table_type))
        );
        break;
      case 2:
        setTablesFiltered(
          tables.filter((table) => ['lounge'].includes(table.table_type))
        );
        break;
      default:
        setTablesFiltered(
          tables.filter((table) =>
            ['table', 'balcony', 'delivery', 'prepaid', 'menu'].includes(
              table.table_type
            )
          )
        );
    }
  }

  async function handleDelete(id) {
    try {
      if (window.confirm('Tem certeza que deseja deletar esta mesa?')) {
        await api.delete(`/restaurants/tables/${id}`);

        getTables();

        toast.success('Mesa deletada com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar mesa');
    }
  }

  const downloadAllCodes = async () => {
    const qrLinks = tables
      .filter((t) => {
        const name = searchTableName(t);
        const notNumbers = ['Balcão', 'Delivery', 'Cardápio'];
        return !notNumbers.includes(name);
      })
      .map((t) => {
        return { name: searchTableName(t), url: t.table_code?.url };
      });

    const qrUrls = qrLinks.map((qr) => qr.url);

    const downloadMany = (urls) => {
      return Promise.all(
        urls.map((url) => {
          // Adding param to avoid problems with cors
          return fetch(`${url}?q=${Date.now()}`).then((resp) => resp.blob());
        })
      );
    };

    const exportZip = (blobs) => {
      const zip = new JSZip();
      blobs.forEach((blob, i) => {
        zip.file(`${qrLinks[i].name}.jpeg`, blob);
      });

      zip.generateAsync({ type: 'blob' }).then((zipFile) => {
        const fileName = `${user.name}-qrcodes.zip`;
        return FileSaver.saveAs(zipFile, fileName);
      });
    };

    const blobs = await downloadMany(qrUrls);
    exportZip(blobs);
  };

  function searchTableName(table) {
    if (table.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table.table_type === 'delivery') {
      return 'Delivery';
    }

    if (table.table_type === 'pos') {
      return 'POS';
    }

    if (table.table_type === 'self-service') {
      return 'Totem';
    }

    if (table.table_type === 'menu') {
      return 'Cardápio';
    }

    if (table.table_type === 'prepaid') {
      return `${table.table_number} - Pagamento antecipado`;
    }

    if (table.table_type === 'lounge') {
      return `${table.table_number} - Lounge / R$${table.price_limit &&
        parseFloat(table.price_limit).toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        }`;
    }

    return table.table_number;
  }
  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'RegisterTables'}`
      );

      const { can_read } = response.data.RegisterTables;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  async function handleSubmitBalcony() {
    setLoading(true);
    try {
      await api.post('restaurants/tables/multiple', {
        start: -1,
        end: -1,
        table_type: 'balcony',
      });

      getTables();
      setCanCreateBalcony(false);
      toast.success('Balcão criado com sucesso!');
    } catch (err) {
      console.log('Create balcony error: ', JSON.stringify(err, null, 2));
      toast.error('Falha ao criar balcão');
    }
    setLoading(false);
  }

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <p>Gerencie as mesas de seu estabelecimento</p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'end',
          }}
        >
          <PersonalOutlineButton
            style={{
              width: 'fit-content',
              marginTop: 20,
              marginRight: 8,
              borderRadius: 100,
            }}
            background="transparent"
            borderColor="#2EC9B7"
            color="#fff"
            fontColor="#2EC9B7"
            onClick={() => downloadAllCodes()}
            message="QR Codes"
            Icon={FaDownload}
          />
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            {!user.only_delivery &&
              (disabledCreate ? (
                <Button
                  title="Adicionar"
                  inverted={true}
                  icon={<FaPlus />}
                  onClick={() => { }}
                />
              ) : (
                <Link to="/register/tables/new">
                  <Button
                    title="Adicionar"
                    icon={<FaPlus />}
                    inverted={true}
                    onClick={() => { }}
                  />
                </Link>
              ))}

            {canCreateBalcony && (
              <RegisterButton
                text="Adicionar Balcão"
                style={{ marginLeft: 15 }}
                onClick={() => handleSubmitBalcony()}
                disabled={loading}
              >
                Adicionar Balcão
              </RegisterButton>
            )}
          </div>
        </div>
      </Header>
      <Tabs
        tabs={['Mesas', 'Comandas']}
        onChangeTab={(e) => {
          setActiveTab(e);
          changeFilter(e);
        }}
        selected={activeTab}
      />

      {/* <TabContent activeTab={activeTab}>
        <TabPane tabId="1"> */}
      <TablesTable borderless firstTab={activeTab === 0}>
        <thead>
          {activeTab === 1 && (
            <tr>
              <th colSpan={6}>
                <AreaSwitcher
                  title="Caso ativo, pedidos de comanda devem exigir número da mesa ou local ao fazer pedido"
                >
                  <Switch
                    onChange={handleUpdateRestaurantSettingsData}
                    draggable
                    checked={requireTableNumber}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={27}
                    width={44}
                    handleDiameter={16}
                    activeBoxShadow="0 0 2px 3px #111"
                    boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                    onColor="#0CCAB4"
                    offColor="#666666"
                  />
                  <span>
                    Exigir que seja informado número da mesa ou local ao fazer pedido
                  </span>
                </AreaSwitcher>
              </th>
            </tr>
          )}
          <tr>
            <th>Número</th>
            <th>Status</th>
            <th>Código</th>
            <th>QRCode</th>
            <th>Imprimir</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {tablesFiltered.map((table) => (
            <tr key={table.id}>
              <td>{searchTableName(table)}</td>
              <td>
                {table.status === 'ongoing' ? (
                  <StatusTag color="#ffc107">
                    <span>Em Uso</span>
                  </StatusTag>
                ) : (
                  <StatusTag color="green">
                    <span>Disponível</span>
                  </StatusTag>
                )}
              </td>
              <td>{table.table_code ? table.table_code.key : 'Sem código'}</td>
              <td>
                {table.table_code && table.table_type !== 'balcony' ? (
                  <ProductImage
                    src={table.table_code.url}
                    alt="Foto do QRCode"
                  />
                ) : (
                  <p>Sem QRCode</p>
                )}
              </td>
              <td>
                {table.table_type !== 'balcony' ? <QrCodePrint table={table} /> : '-'}
              </td>
              <td>
                {table.table_type !== 'menu' &&
                  table.table_type !== 'delivery' &&
                  table.table_type !== 'balcony' && (
                    <Actions>
                      <StyledLink
                        to={{
                          pathname: 'tables/edit',
                          state: {
                            table,
                          },
                        }}
                      >
                        <MdEdit size={20} />
                      </StyledLink>
                      <Delete onClick={() => handleDelete(table.id)}>
                        <MdDelete size={20} />
                      </Delete>
                    </Actions>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </TablesTable>
      {/* </TabPane>
      </TabContent> */}
    </Container>
  );
}

export default Tables;