import React, { useCallback, useEffect, useState } from 'react';
import { ActionArea, ButtonsArea, Container, Header, SearchArea, Card, StyledModal, InputsArea, CustomTooltip, TableColorsStyled } from './styles'
import { PageTitle } from '~/components/PageTitle';
import { Button, Divider, IconHelpFilled, Input, NotificationBanner, Select, Spinner } from 'takeat-design-system-ui-kit';
import { IconInput } from 'ui-kit-takeat';
import Product from './Product';
import { ModalBody, ModalFooter } from 'reactstrap';
import api from '~/services/api';
import toast from 'react-hot-toast';
import { useAuth } from '~/context/AuthContext';

function Markup() {
  const { user } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [isModalChangeMarkupOpened, setIsModalChangeMarkupOpened] = useState(false)
  const [isModalChangeCmvOpened, setIsModalChangeCmvOpened] = useState(false)
  const [products, setProducts] = useState([])
  const [categoriesOptions, setCategoriesOptions] = useState([])

  function toggleModalMarkup() {
    setIsModalChangeMarkupOpened(!isModalChangeMarkupOpened)
  }

  function toggleModalCmv() {
    setIsModalChangeCmvOpened(!isModalChangeCmvOpened)
  }

  const getProducts = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await api.get('/restaurants/markup/products')
      if (response.data.products) {
        setProducts(response.data.products)
      }

      if (response.data.categories) {
        const categoriesFormatted = response.data.categories.map(item => {
          return {
            value: item.name,
            label: item.name
          }
        })

        setCategoriesOptions([{ label: "Todas as categorias", value: "all" }, ...categoriesFormatted])
      }
      setIsLoading(false)
    } catch (error) {
      toast.error("Erro ao baixar produtos.")
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    getProducts()
  }, [getProducts])

  const [categoryFiltered, setCategoryFiltered] = useState(null)
  const [searchTerm, setSearchTerm] = useState("");

  function handleSetCategoryFilter(e) {
    setCategoryFiltered(e)
  }

  const filteredProducts = products.filter(item => {
    if (categoryFiltered !== "all" && categoryFiltered) {
      if (item.category !== categoryFiltered) {
        return false;
      }
    }

    if (searchTerm) {
      const normalizedName = item.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      const normalizedSearch = searchTerm
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      return normalizedName.includes(normalizedSearch);
    }

    return true;
  });

  const [markupSetter, setMarkupSetter] = useState(null)
  const [markupDeliverySetter, setMarkupDeliverySetter] = useState(null)

  const [markupDefault, setMarkupDefault] = useState(null)
  const [markupDeliveryDefault, setMarkupDeliveryDefault] = useState(null)

  const handleChangeMarkup = useCallback(async () => {
    try {

      const data = {};

      if (markupSetter) data.markup = markupSetter;
      if (markupDeliverySetter) data.markup_delivery = markupDeliverySetter;

      await api.put('/restaurants/markup/replicate', data)

      setMarkupDeliveryDefault(markupDeliverySetter)
      setMarkupDefault(markupSetter)

      toggleModalMarkup()
      toast.success("Markup definido com sucesso")
    } catch (error) {
      toast.error("Erro ao definir markup")
    }

  }, [markupSetter, markupDeliverySetter])

  const [cmvPercentualSetter, setCmvPercentualSetter] = useState(null)
  const [cmvPercentualDeliverySetter, setCmvPercentualDeliverySetter] = useState(null)

  const handleChangeCmvPercentual = useCallback(async () => {
    try {

      const data = {};

      if (cmvPercentualSetter > 0) {
        data.markup = 100 / cmvPercentualSetter;
      }

      if (cmvPercentualDeliverySetter > 0) {
        data.markup_delivery = 100 / cmvPercentualDeliverySetter;
      }

      await api.put('/restaurants/markup/replicate', data)

      if (cmvPercentualSetter > 0) {
        setMarkupDefault(data.markup = 100 / cmvPercentualSetter)
      }

      if (cmvPercentualDeliverySetter > 0) {
        setMarkupDeliveryDefault(data.markup_delivery = 100 / cmvPercentualDeliverySetter)
      }
      toggleModalCmv()
      toast.success("%CMV definido com sucesso")
    } catch (error) {
      toast.error("Erro ao definir %CMV")
    }

  }, [cmvPercentualSetter, cmvPercentualDeliverySetter])

  //tooltip
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Container>
      <StyledModal
        isOpen={isModalChangeMarkupOpened}
        toggle={toggleModalMarkup}
      >
        <div style={{ padding: 16, height: 56 }}>
          <h3 style={{ fontSize: 18, fontWeight: 600 }}>
            Alterar Markup
          </h3>
        </div>
        <ModalBody style={{ padding: 16 }}>
          {user.only_delivery ? (
            <InputsArea>
              <Input
                value={markupDeliverySetter}
                onChange={e => setMarkupDeliverySetter(e.target.value)}
                placeholder='0'
                isCurrency
              />
            </InputsArea>
          ) : (
            <InputsArea>
              <Input
                value={markupSetter}
                onChange={e => setMarkupSetter(e.target.value)}
                label={(user?.delivery_info?.is_delivery_allowed || user?.delivery_info?.is_withdrawal_allowed) && "Presencial"}
                placeholder='0'
                isCurrency
                style={{
                  width: 250,
                }}
              />
              {(user?.delivery_info?.is_delivery_allowed || user?.delivery_info?.is_withdrawal_allowed) && (
                <Input
                  value={markupDeliverySetter}
                  onChange={e => setMarkupDeliverySetter(e.target.value)}
                  style={{
                    width: 250,
                  }}
                  label="Delivery"
                  placeholder='0'
                  isCurrency
                />
              )}
            </InputsArea>
          )}

          <NotificationBanner
            variant='warning'
            isOpen
            style={{ alignItems: "center" }}
          >
            <p>
              Esta alteração será aplicada apenas aos itens com markup configurado em 1.
            </p>
          </NotificationBanner>
        </ModalBody>
        <ModalFooter
          style={
            {
              justifyContent: "flex-end",
              padding: 16,

            }
          }>
          <Divider
            style={{
              marginBottom: 16
            }}
          />
          <Button
            customColor='#C8131B'
            onClick={toggleModalMarkup}
          >
            Cancelar
          </Button>
          <Button
            customColor='#2EC9B7'
            style={{
              marginLeft: 8
            }}
            onClick={handleChangeMarkup}
          >
            Alterar
          </Button>
        </ModalFooter>
      </StyledModal>

      <StyledModal
        isOpen={isModalChangeCmvOpened}
        toggle={toggleModalCmv}
      >
        <div style={{ padding: 16, height: 56 }}>
          <h3 style={{ fontSize: 18, fontWeight: 600 }}>
            Alterar % CMV
          </h3>
        </div>
        <ModalBody style={{ padding: 16 }}>
          {user?.only_delivery ? (
            <InputsArea>
              <Input
                value={cmvPercentualDeliverySetter}
                onChange={e => setCmvPercentualDeliverySetter(e.target.value)}
                placeholder='%'
                isCurrency
              />
            </InputsArea>
          ) : (
            <InputsArea>
              <Input
                value={cmvPercentualSetter}
                onChange={e => setCmvPercentualSetter(e.target.value)}
                label={(user?.delivery_info?.is_delivery_allowed || user?.delivery_info?.is_withdrawal_allowed) && "Presencial"}
                placeholder='%'
                style={{
                  width: 250,
                }}
                isCurrency
              />
              {(user?.delivery_info?.is_delivery_allowed || user?.delivery_info?.is_withdrawal_allowed) && (
                <Input
                  value={cmvPercentualDeliverySetter}
                  onChange={e => setCmvPercentualDeliverySetter(e.target.value)}
                  style={{
                    width: 250,
                  }}
                  label="Delivery"
                  placeholder='%'
                  isCurrency
                />
              )}
            </InputsArea>
          )}
          <NotificationBanner
            variant='warning'
            isOpen
            style={{ alignItems: "center" }}
          >
            <p>
              Esta alteração será aplicada apenas aos itens com %CMV configurado em 100.
            </p>
          </NotificationBanner>
        </ModalBody>
        <ModalFooter
          style={
            {
              justifyContent: "flex-end",
              padding: 16,

            }
          }>
          <Divider
            style={{
              marginBottom: 16
            }}
          />
          <Button
            customColor='#C8131B'
            onClick={toggleModalCmv}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleChangeCmvPercentual}
            customColor='#2EC9B7'
            style={{
              marginLeft: 8
            }}
          >
            Alterar
          </Button>
        </ModalFooter>
      </StyledModal>
      <Header>
        <PageTitle>
          Precificação
        </PageTitle>
        <span>
          Agora você pode editar a precificação do seu produto, seja pelo seu markup ou pelo CMV.
        </span>
        <span style={{ alignItems: 'left', display: 'flex', gap: 8, marginTop: 8 }}>
          <span style={{ fontSize: 12 }}>Qual o significado das cores do resultado?</span> <IconHelpFilled fill="#545454" id="title-info" fontSize={18} />
          <CustomTooltip
            isOpen={tooltipOpen}
            toggle={toggle}
            target="title-info"
            placement='bottom'
          >
            <p style={{ color: "#fff", textAlign: 'left', fontWeight: "bold" }}>
              Resultados das cores tabeladas em RESULTADO
            </p>
            <p style={{ color: "#2EC9B7", textAlign: 'left' }}>
              Verde e seta para cima = Resultado Positivo
            </p>
            <p style={{ color: "#E5A1A4", textAlign: 'left' }}>
              Vermelho e seta para baixo = Resultado Negativo
            </p>
            {/* <TableColorsStyled>
              <tbody>
                <tr style={{ background: "#BCE3DE" }}>
                  <td>{`= ou > 0%`}</td>
                  <td>{`= ou < 30%`}</td>
                </tr>
                <tr style={{ background: "#FFEBC8" }}>
                  <td>{`= ou > 31%`}</td>
                  <td>{`= ou < 39%`}</td>
                </tr>
                <tr style={{ background: "#E5A1A4" }}>
                  <td>{`= ou > 40%`}</td>
                  <td>{`= ou < 100%`}</td>
                </tr>
              </tbody>
            </TableColorsStyled> */}

          </CustomTooltip>
        </span>
      </Header>
      <ActionArea>
        <SearchArea>
          <IconInput
            placeholder='Buscar'
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value)
            }}
            containerStyles={
              {
                height: 38,
                background: "#fff",
                border: "1px solid #7a7a7a",
                borderRadius: 8,
                marginRight: 8,
              }
            }
            inputStyles={
              {
                fontSize: 14
              }
            }
            isClearable
            deleteButton={() => { setSearchTerm('') }}
          />
          <Select
            placeholder='Selecionar Categoria'
            options={categoriesOptions}
            onChange={(e) => { handleSetCategoryFilter(e.value) }}
          />
        </SearchArea>

        <ButtonsArea>
          <Button
            variant='primary'
            customColor='#2EC9B7'
            style={{
              marginRight: 8
            }}
            onClick={toggleModalMarkup}
          >
            Alterar Markup
          </Button>
          <Button
            variant='primary'
            customColor='#01AFFF'
            onClick={toggleModalCmv}
          >
            Alterar %CMV
          </Button>
        </ButtonsArea>

      </ActionArea>
      <Card>
        {filteredProducts && filteredProducts.length > 0 ? filteredProducts.map((item, index) => (
          <Product
            key={index}
            product={item}
            markupDefault={markupDefault}
            markupDeliveryDefault={markupDeliveryDefault}
          />
        )) : (
          <div>
            {isLoading ? (
              <Spinner />
            ) : (
              <p>
                Não há itens nessa categoria.
              </p>
            )}

          </div>
        )}
      </Card>

    </Container>
  )
}

export default Markup