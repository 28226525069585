import React, { useCallback, useEffect, useState } from 'react'
import { CategoryName, Container, CustomTooltip, Header, InputTableStyled, ProductInfo, ProductInfoArea, ProductName, ResultButton, StockArea, StyledModal, TabelArea, TableStyled } from './styles'
import { Button, IconArrowDown, IconArrowUp, IconHelpFilled, IconInfoFilled, IconPencilFilled, IconQuestion, IconTrashFilled, Input } from 'takeat-design-system-ui-kit'
import { IoIosArrowDown } from 'react-icons/io'
import { ReactComponent as Check } from '~/assets/img/check.svg'
import EditStock from './EditStock'
import formatValue from '~/services/formatValue'
import api from '~/services/api'
import toast from 'react-hot-toast'
import { useAuth } from '~/context/AuthContext'

export default function Product({ product, markupDeliveryDefault, markupDefault }) {
  const { user } = useAuth()
  const [inputsAndIntermediaries, setInputsAndIntermediaries] = useState([])
  const [showStock, setShowStock] = useState(false)
  const [isModalEditStockOpened, setIsModalEditStockOpened] = useState(false)
  const [markup, setMarkup] = useState(product.markup);
  const [markupDelivery, setMarkupDelivery] = useState(product.markup_delivery);
  const [price, setPrice] = useState(product.price || 0);
  const [deliveryPrice, setDeliveryPrice] = useState(product.delivery_price || product.price || 0);
  const [cmvPercentual, setCmvPercentual] = useState(100 / product.markup);
  const [cmvPercentualDelivery, setCmvPercentualDelivery] = useState(100 / product.markup_delivery);
  const [ideal, setIdeal] = useState(0);
  const [result, setResult] = useState(0);
  const [idealDelivery, setIdealDelivery] = useState(0);
  const [resultDelivery, setResultDelivery] = useState(0);
  const [cmv, setCmv] = useState(0)

  useEffect(() => {
    const newCmv = product.current_cmv ? product.current_cmv :
      inputsAndIntermediaries.reduce((acc, item) => {
        const quantidade = parseFloat(item.quantidade) || 0;
        const unitaryPrice = parseFloat(item.unitary_price) || 0;
        return acc + quantidade * unitaryPrice;
      }, 0)

    setCmv(newCmv)
  }, [inputsAndIntermediaries])

  useEffect(() => {
    if (markupDefault) {
      if (markup === 1 || cmvPercentual === 100) {
        setMarkup(markupDefault);
        setCmvPercentual(100 / markupDefault);
      }
    }
    if (markupDeliveryDefault) {
      if (markupDelivery === 1 || cmvPercentualDelivery === 100) {
        setMarkupDelivery(markupDeliveryDefault);
        setCmvPercentualDelivery(100 / markupDeliveryDefault);
      }
    }
  }, [markupDefault, markupDeliveryDefault]);

  useEffect(() => {
    const newPrice = price - ideal
    const newPriceDelivery = deliveryPrice - idealDelivery

    setResult(isNaN(newPrice) ? 0 : newPrice);
    setResultDelivery(isNaN(newPriceDelivery) ? 0 : newPriceDelivery);
  }, [price, deliveryPrice])

  useEffect(() => {
    const updatedIdeal = markup * cmv;
    const updatedResult = price - updatedIdeal;

    const updatedIdealDelivery = markupDelivery * cmv;
    const updatedResultDelivery = deliveryPrice - updatedIdealDelivery;

    setIdeal(isNaN(updatedIdeal) ? 0 : updatedIdeal);
    setResult(isNaN(updatedResult) ? 0 : updatedResult);
    setIdealDelivery(updatedIdealDelivery || 0);
    setResultDelivery(updatedResultDelivery || 0);
  }, [markup, markupDelivery, cmv]);

  useEffect(() => {
    const newMarkup = 100 / cmvPercentual;
    if (markup !== newMarkup) setMarkup(newMarkup);
  }, [cmvPercentual]);

  useEffect(() => {
    const newMarkupDelivery = 100 / cmvPercentualDelivery;
    if (markupDelivery !== newMarkupDelivery) setMarkupDelivery(newMarkupDelivery);
  }, [cmvPercentualDelivery]);

  useEffect(() => {
    const newCmvPercentual = 100 / markup;
    if (cmvPercentual !== newCmvPercentual) setCmvPercentual(newCmvPercentual);
  }, [markup]);

  useEffect(() => {
    const newCmvPercentualDelivery = 100 / markupDelivery;
    if (cmvPercentualDelivery !== newCmvPercentualDelivery)
      setCmvPercentualDelivery(newCmvPercentualDelivery);
  }, [markupDelivery]);

  useEffect(() => {
    setInputsAndIntermediaries([
      ...product.inputs,
      ...product.intermediaries
    ])
  }, [product])

  function handleShowStockArea() {
    setShowStock(!showStock)
  }

  function toggleModalEditStock() {
    setIsModalEditStockOpened(!isModalEditStockOpened)
  }

  const handleDelete = useCallback(async (item) => {
    try {
      const payload = {
        product_id: item.product_id,
        ...(item.input_id
          ? { input_id: item.input_id }
          : { intermediary_id: item.intermediary_id }),
      };

      await api.put('/restaurants/markup/delete-input-or-inter', payload);

      setInputsAndIntermediaries((old) =>
        old.filter((it) =>
          item.input_id
            ? it.input_id !== item.input_id
            : it.intermediary_id !== item.intermediary_id
        )
      );

      toast.success(
        item.input_id
          ? "Insumo deletado com sucesso"
          : "Intermediário deletado com sucesso"
      );
    } catch (error) {
      toast.error("Erro ao deletar do estoque");
      console.error(error);
    }
  }, []);

  const [buttonSubmitDisabled, setButtonSubmitDisabled] = useState(true)

  const handleSubmit = useCallback(async () => {
    try {
      setButtonSubmitDisabled(true)

      const data = {
        markup: markup,
        markup_delivery: markupDelivery,
        price: price,
        delivery_price: deliveryPrice,
      }

      if (product.is_cmv_manual) {
        data.current_cmv = cmv
        data.is_cmv_manual = true
      }

      await api.put(`/restaurants/products/${product.id}`, data)
      toast.success("Produto atualizado com sucesso")

    } catch (error) {
      toast.error("Erro ao atualizar produto.")
    }

  }, [markup, deliveryPrice, price, markupDelivery, cmv])

  const getButtonColor = useCallback((type) => {
    // const percentage = type === "delivery" ? (Number(resultDelivery) / Number(idealDelivery)) * 100 : (Number(result) / Number(ideal)) * 100;
    const percentage = type === "delivery" ? resultDelivery : result

    if (percentage < 0) {
      return '#E5A1A4';
    } else {
      return '#BCE3DE';
    }

  }, [resultDelivery, result]);

  const getIcon = useCallback((type) => {
    // const percentage = type === "delivery" ? (Number(resultDelivery) / Number(idealDelivery)) * 100 : (Number(result) / Number(ideal)) * 100;
    const percentage = type === "delivery" ? resultDelivery : result

    if (percentage < 0) {
      return <IconArrowDown style={{ width: "0.95rem", color: "#C8131B", fill: "#C8131B" }} />;
    } else {
      return <IconArrowUp style={{ width: "0.95rem", fill: "#2EC9B7" }} />;
    }

  }, [result, resultDelivery]);

  function handleChangeCmv(e) {
    if (product.is_cmv_manual) {
      setCmv(e)
    }
  }

  //tooltips
  const [tooltipStates, setTooltipStates] = useState({});

  const toggleTooltip = (id) => {
    setTooltipStates((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <Container>
      <StyledModal
        isOpen={isModalEditStockOpened}
        toggle={toggleModalEditStock}
        size="lg"
      >
        <EditStock
          toggleModalEditStock={toggleModalEditStock}
          product={product}
          inputsAndIntermediaries={inputsAndIntermediaries}
          setInputsAndIntermediaries={setInputsAndIntermediaries}
        />
      </StyledModal>
      {user.only_delivery ? (
        <Header>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{
              display: "flex",
              alignItems: "center",

            }}>
              <IoIosArrowDown
                color='#222'
                size={21}
                style={{
                  marginRight: 16,
                  transform: `rotate(${showStock ? "-180deg" : "0deg"})`,
                  transition: "transform 0.3s ease",
                }}
                onClick={handleShowStockArea}
                cursor="pointer"
              />
            </div>
            <ProductInfoArea>
              <ProductInfo>
                <ProductName>
                  {product.name}
                </ProductName>
                <CategoryName>
                  {product.category}
                </CategoryName>
              </ProductInfo>
            </ProductInfoArea>
          </div>
          <TabelArea>
            <TableStyled>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    Markup
                    <IconInfoFilled fill="#7a7a7a" id={`title-markup-${product.id}`} style={{ marginLeft: 4 }} />
                    <CustomTooltip
                      isOpen={tooltipStates[`markup-${product.id}`] || false}
                      toggle={() => toggleTooltip(`markup-${product.id}`)}
                      target={`title-markup-${product.id}`}
                      placement="bottom"
                    >
                      O valor será o multiplicador do CMV.
                    </CustomTooltip>
                  </th>
                  <th>
                    %CMV
                    <IconInfoFilled fill="#7a7a7a" id={`title-cmv-${product.id}`} style={{ marginLeft: 4 }} />
                    <CustomTooltip
                      isOpen={tooltipStates[`cmv-${product.id}`] || false}
                      toggle={() => toggleTooltip(`cmv-${product.id}`)}
                      target={`title-cmv-${product.id}`}
                      placement="bottom"
                    >
                      Valor em porcentagem do CMV / PREÇO ATUAL.
                    </CustomTooltip>
                  </th>
                  <th>
                    $ Atual
                    <IconInfoFilled fill="#7a7a7a" id={`title-actual-${product.id}`} style={{ marginLeft: 4 }} />
                    <CustomTooltip
                      isOpen={tooltipStates[`actual-${product.id}`] || false}
                      toggle={() => toggleTooltip(`actual-${product.id}`)}
                      target={`title-actual-${product.id}`}
                      placement="bottom"
                    >
                      Valor de venda atual do seu produto em seu cardápio.
                    </CustomTooltip>
                  </th>
                  <th>
                    $ Ideal
                    <IconInfoFilled fill="#7a7a7a" id={`title-ideal-${product.id}`} style={{ marginLeft: 4 }} />
                    <CustomTooltip
                      isOpen={tooltipStates[`ideal-${product.id}`] || false}
                      toggle={() => toggleTooltip(`ideal-${product.id}`)}
                      target={`title-ideal-${product.id}`}
                      placement="bottom"
                    >
                      Valor de venda ideal de acordo com o markup e %CMV definido.
                    </CustomTooltip>
                  </th>
                  <th>
                    Resultado
                    <IconInfoFilled fill="#7a7a7a" id={`title-result-${product.id}`} style={{ marginLeft: 4 }} />
                    <CustomTooltip
                      isOpen={tooltipStates[`result-${product.id}`] || false}
                      toggle={() => toggleTooltip(`result-${product.id}`)}
                      target={`title-result-${product.id}`}
                      placement="bottom"
                    >
                      Diferença entre o valor de venda atual menos o valor ideal.
                    </CustomTooltip>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {
                        product.is_cmv_manual ? (
                          <Input
                            value={cmv}
                            onChange={e => { handleChangeCmv(e.target.value) }}
                            disabled={!product.is_cmv_manual}
                            style={{
                              width: 104,
                              height: 32,
                              textAlign: "center",
                              marginTop: 16
                            }}
                            onClick={() => { setButtonSubmitDisabled(false) }}
                            hasPrefix
                            isCurrency
                          />
                        ) : (
                          <Input
                            value={cmv > 0 ? cmv : "R$ 0,00"}
                            style={{
                              width: 104,
                              height: 32,
                              textAlign: "center",
                              border: "none",
                              background: "#f6f6f6"

                            }}
                            hasPrefix={cmv > 0 ? true : false}
                            isCurrency={cmv > 0 ? true : false}
                          />
                        )
                      }
                      {product.is_cmv_manual && <span style={{ fontSize: 12 }}>*CMV manual</span>}
                    </div>

                  </td>
                  <td>
                    <Input
                      value={markupDelivery}
                      onChange={e => setMarkupDelivery(e.target.value)}
                      onClick={() => { setButtonSubmitDisabled(false) }}
                      style={{
                        maxWidth: 104,
                        height: 32,
                        textAlign: "center"
                      }}
                      isCurrency
                    />
                  </td>
                  <td>
                    <Input
                      value={cmvPercentualDelivery}
                      onClick={() => { setButtonSubmitDisabled(false) }}
                      onChange={e => setCmvPercentualDelivery(e.target.value)}
                      style={{
                        maxWidth: 104,
                        height: 32,
                        textAlign: "center"
                      }}
                      isCurrency
                    />
                  </td>
                  <td>
                    <Input
                      value={deliveryPrice}
                      onClick={() => { setButtonSubmitDisabled(false) }}
                      onChange={e => setDeliveryPrice(e.target.value)}
                      style={{
                        maxWidth: 104,
                        height: 32,
                        textAlign: "center"
                      }}
                    />
                  </td>
                  <td>
                    <ResultButton
                      style={{
                        maxWidth: 104,
                        height: 32,
                        borderRadius: 8,
                        color: "#000",
                        cursor: "auto"
                      }}
                      customColor='#F6F6F6'
                    >
                      {formatValue(idealDelivery)}
                    </ResultButton>
                  </td>
                  <td>
                    <ResultButton
                      style={{
                        maxWidth: 104,
                        height: 32,
                        borderRadius: 8,
                        cursor: "auto",
                        background: idealDelivery > 0 ? getButtonColor("delivery") : "#f6f6f6",
                        color: "#000"
                      }}
                      icon={idealDelivery > 0 && getIcon("delivery")}
                    >
                      {idealDelivery > 0 ? formatValue(Math.abs(resultDelivery)) : "-"}
                    </ResultButton>
                  </td>
                  <td>
                    <Button
                      icon={<Check />}
                      customColor='#2EC9B7'
                      style={{
                        height: 32,
                        width: 32,
                        borderRadius: 8
                      }}
                      disabled={buttonSubmitDisabled}
                      onClick={handleSubmit}
                    />
                  </td>
                </tr>
              </tbody>
            </TableStyled>
          </TabelArea>
        </Header>
      ) : (
        <Header>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{
              display: "flex",
              alignItems: "center",
            }}>
              <IoIosArrowDown
                color='#222'
                size={21}
                style={{
                  marginRight: 16,
                  transform: `rotate(${showStock ? "-180deg" : "0deg"})`,
                  transition: "transform 0.3s ease",
                }}
                onClick={handleShowStockArea}
                cursor="pointer"
              />
            </div>
            <ProductInfoArea>
              <ProductInfo>
                <ProductName>
                  {product.name}
                </ProductName>
                <CategoryName>
                  {product.category}
                </CategoryName>
              </ProductInfo>
              {(user?.delivery_info?.is_delivery_allowed || user?.delivery_info?.is_withdrawal_allowed) && (
                <div style={{ display: "flex", flexDirection: "column", marginTop: 8 }}>
                  <span style={{ fontSize: 12, fontWeight: 600 }}>
                    Custo do Item (CMV):</span>
                  {
                    product.is_cmv_manual ? (
                      <Input
                        value={cmv}
                        onChange={e => { handleChangeCmv(e.target.value) }}
                        disabled={!product.is_cmv_manual}
                        style={{
                          width: 140,
                          height: 32,
                          textAlign: "center"
                        }}
                        onClick={() => { setButtonSubmitDisabled(false) }}
                        hasPrefix
                        isCurrency
                      />
                    ) : (
                      <Input
                        value={cmv > 0 ? cmv : "R$ 0,00"}
                        style={{
                          width: 140,
                          height: 32,
                          textAlign: "center",
                          border: "none",
                          background: "#f6f6f6"

                        }}
                        hasPrefix={cmv > 0 ? true : false}
                        isCurrency={cmv > 0 ? true : false}
                      />
                    )
                  }

                  {product.is_cmv_manual && <span style={{ fontSize: 12 }}>*CMV manual</span>}
                </div>

              )}
            </ProductInfoArea>
          </div>
          <TabelArea>
            <TableStyled>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    Markup
                    <IconInfoFilled fill="#7a7a7a" id={`title-markup-${product.id}`} style={{ marginLeft: 4 }} />
                    <CustomTooltip
                      isOpen={tooltipStates[`markup-${product.id}`] || false}
                      toggle={() => toggleTooltip(`markup-${product.id}`)}
                      target={`title-markup-${product.id}`}
                      placement="bottom"
                    >
                      O valor será o multiplicador do CMV.
                    </CustomTooltip>
                  </th>
                  <th>
                    %CMV
                    <IconInfoFilled fill="#7a7a7a" id={`title-cmv-${product.id}`} style={{ marginLeft: 4 }} />
                    <CustomTooltip
                      isOpen={tooltipStates[`cmv-${product.id}`] || false}
                      toggle={() => toggleTooltip(`cmv-${product.id}`)}
                      target={`title-cmv-${product.id}`}
                      placement="bottom"
                    >
                      Valor em porcentagem do CMV / PREÇO ATUAL.
                    </CustomTooltip>
                  </th>
                  <th>
                    $ Atual
                    <IconInfoFilled fill="#7a7a7a" id={`title-actual-${product.id}`} style={{ marginLeft: 4 }} />
                    <CustomTooltip
                      isOpen={tooltipStates[`actual-${product.id}`] || false}
                      toggle={() => toggleTooltip(`actual-${product.id}`)}
                      target={`title-actual-${product.id}`}
                      placement="bottom"
                    >
                      Valor de venda atual do seu produto em seu cardápio.
                    </CustomTooltip>
                  </th>
                  <th>
                    $ Ideal
                    <IconInfoFilled fill="#7a7a7a" id={`title-ideal-${product.id}`} style={{ marginLeft: 4 }} />
                    <CustomTooltip
                      isOpen={tooltipStates[`ideal-${product.id}`] || false}
                      toggle={() => toggleTooltip(`ideal-${product.id}`)}
                      target={`title-ideal-${product.id}`}
                      placement="bottom"
                    >
                      Valor de venda ideal de acordo com o markup e %CMV definido.
                    </CustomTooltip>
                  </th>
                  <th>
                    Resultado
                    <IconInfoFilled fill="#7a7a7a" id={`title-result-${product.id}`} style={{ marginLeft: 4 }} />
                    <CustomTooltip
                      isOpen={tooltipStates[`result-${product.id}`] || false}
                      toggle={() => toggleTooltip(`result-${product.id}`)}
                      target={`title-result-${product.id}`}
                      placement="bottom"
                    >
                      Diferença entre o valor de venda atual menos o valor ideal.
                    </CustomTooltip>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {(user?.delivery_info?.is_delivery_allowed || user?.delivery_info?.is_withdrawal_allowed) ? (
                    <td>Presencial</td>
                  ) : (
                    <td>
                      <Input
                        value={`CMV: ${formatValue(inputsAndIntermediaries.reduce((acc, item) => {
                          const quantidade = parseFloat(item.quantidade) || 0;
                          const unitaryPrice = parseFloat(item.unitary_price) || 0;
                          return acc + quantidade * unitaryPrice;
                        }, 0))}`}
                        style={{
                          width: 131,
                          height: 32,
                        }}
                      />
                    </td>
                  )}
                  <td>
                    <Input
                      value={markup}
                      onChange={e => setMarkup(e.target.value)}
                      style={{
                        maxWidth: 104,
                        height: 32,
                        textAlign: "center"
                      }}
                      onClick={() => { setButtonSubmitDisabled(false) }}
                      isCurrency
                    /></td>
                  <td>
                    <Input
                      value={cmvPercentual}
                      onChange={e => { setCmvPercentual(e.target.value) }}
                      isCurrency
                      style={{
                        maxWidth: 104,
                        height: 32,
                        textAlign: "center"
                      }}
                      onClick={() => { setButtonSubmitDisabled(false) }}
                    /></td>
                  <td>
                    <Input
                      onChange={e => setPrice(e.target.value)}
                      onClick={() => { setButtonSubmitDisabled(false) }}
                      value={price}
                      style={{
                        maxWidth: 104,
                        height: 32,
                        textAlign: "center"
                      }}
                      isCurrency
                      hasPrefix
                    /></td>
                  <td>
                    <ResultButton
                      style={{
                        maxWidth: 104,
                        height: 32,
                        borderRadius: 8,
                        color: "#000",
                        cursor: "auto",
                        background: "#F6F6F6"
                      }}
                    >
                      {formatValue(ideal)}
                    </ResultButton>
                  </td>
                  <td>
                    <ResultButton
                      style={{
                        maxWidth: 118,
                        height: 32,
                        borderRadius: 8,
                        cursor: "auto",
                        color: "#000",
                        background: ideal > 0 ? getButtonColor() : "#f6f6f6"

                      }}
                      icon={ideal > 0 && getIcon()}
                    >
                      {ideal > 0 ? formatValue(Math.abs(result)) : "-"}
                    </ResultButton>
                  </td>
                  {(user?.delivery_info?.is_delivery_allowed || user?.delivery_info?.is_withdrawal_allowed) ? (
                    <td rowSpan="2">
                      <Button
                        icon={<Check />}
                        customColor='#2EC9B7'
                        style={{ height: "5rem", borderRadius: 8 }}
                        disabled={buttonSubmitDisabled}
                        onClick={handleSubmit}
                      />
                    </td>
                  ) : (
                    <td>
                      <Button
                        icon={<Check />}
                        customColor='#2EC9B7'
                        style={{ width: 32, borderRadius: 8 }}
                        disabled={buttonSubmitDisabled}
                        onClick={handleSubmit}
                      />
                    </td>
                  )}
                </tr>
                {(user?.delivery_info?.is_delivery_allowed || user?.delivery_info?.is_withdrawal_allowed) && (
                  <tr>
                    <td>Delivery</td>
                    <td>
                      <Input
                        value={markupDelivery}
                        onChange={e => setMarkupDelivery(e.target.value)}
                        onClick={() => { setButtonSubmitDisabled(false) }}
                        style={{
                          maxWidth: 104,
                          height: 32,
                          textAlign: "center"
                        }}
                        isCurrency
                      />
                    </td>
                    <td>
                      <Input
                        value={cmvPercentualDelivery}
                        onChange={e => setCmvPercentualDelivery(e.target.value)}
                        onClick={() => { setButtonSubmitDisabled(false) }}
                        style={{
                          maxWidth: 104,
                          height: 32,
                          textAlign: "center"
                        }}
                        isCurrency
                      />
                    </td>
                    <td>
                      <Input
                        value={deliveryPrice}
                        onChange={e => setDeliveryPrice(e.target.value)}
                        onClick={() => { setButtonSubmitDisabled(false) }}
                        style={{
                          maxWidth: 104,
                          height: 32,
                          textAlign: "center"
                        }}
                        isCurrency
                        hasPrefix
                      />
                    </td>
                    <td>
                      <ResultButton
                        style={{
                          maxWidth: 104,
                          height: 32,
                          borderRadius: 8,
                          color: "#000",
                          cursor: "auto",
                          background: "#F6F6F6"
                        }}
                      >
                        {formatValue(idealDelivery)}
                      </ResultButton>
                    </td>
                    <td>
                      <ResultButton
                        style={{
                          maxWidth: 118,
                          height: 32,
                          borderRadius: 8,
                          cursor: "auto",
                          background: idealDelivery > 0 ? getButtonColor("delivery") : "#f6f6f6",
                          color: "#000"
                        }}
                        variant='tonal'
                        icon={idealDelivery > 0 && getIcon("delivery")}
                      >
                        {idealDelivery > 0 ? formatValue(Math.abs(resultDelivery)) : '-'}
                      </ResultButton>
                    </td>
                  </tr>
                )}
              </tbody>
            </TableStyled>
          </TabelArea>
        </Header>
      )}

      <StockArea show={showStock} style={{ marginTop: showStock ? 10 : 0 }}>
        <InputTableStyled>
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Nome</th>
              <th>
                Consumo
                <IconInfoFilled fill="#7a7a7a" id={`title-qtd-${product.id}`} style={{ marginLeft: 4 }} />
                <CustomTooltip
                  isOpen={tooltipStates[`qtd-${product.id}`] || false}
                  toggle={() => toggleTooltip(`qtd-${product.id}`)}
                  target={`title-qtd-${product.id}`}
                  placement="bottom"
                >
                  Quantidade e unidade de medida usada na ficha técnica.
                </CustomTooltip>
              </th>
              <th>
                Valor
                <IconInfoFilled fill="#7a7a7a" id={`title-value-${product.id}`} style={{ marginLeft: 4 }} />
                <CustomTooltip
                  isOpen={tooltipStates[`value-${product.id}`] || false}
                  toggle={() => toggleTooltip(`value-${product.id}`)}
                  target={`title-value-${product.id}`}
                  placement="bottom"
                >
                  Valor de custo daquele insumo/produto intermediário na ficha técnica.
                </CustomTooltip>
              </th>
              <th>
                Estoque
                <IconInfoFilled fill="#7a7a7a" id={`title-amount-${product.id}`} style={{ marginLeft: 4 }} />
                <CustomTooltip
                  isOpen={tooltipStates[`amount-${product.id}`] || false}
                  toggle={() => toggleTooltip(`amount-${product.id}`)}
                  target={`title-amount-${product.id}`}
                  placement="bottom"
                >
                  Quantidade daquele produto no estoque.
                </CustomTooltip>
              </th>
              <th>
                <Button
                  customColor='#01AFFF'
                  icon={<IconPencilFilled style={{ width: 14 }} />}
                  style={{
                    borderRadius: 8,
                    width: 84,
                    height: 32,
                    fontSize: 12
                  }}
                  onClick={toggleModalEditStock}
                >
                  Editar
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>
            {inputsAndIntermediaries && inputsAndIntermediaries.length > 0 && inputsAndIntermediaries.map(item => (
              <tr key={item.id}>
                <td>{item.input_id ? "Insumos" : "Intermediários"}</td>
                <td>{item.name}</td>
                <td>{`${item.quantidade} (${item.unidade})`} </td>
                <td>{formatValue(item.quantidade * item.unitary_price)} </td>
                <td>{`${item.available_amount || "-"} ${item.unidade}`} </td>
                <td>
                  <IconTrashFilled
                    fill="#c8131b"
                    fontSize={24}
                    style={{
                      marginRight: 26,
                      cursor: "pointer"
                    }}
                    onClick={() => { handleDelete(item) }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </InputTableStyled>
      </StockArea>
    </Container>
  )
}
