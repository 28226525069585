import React, { useState, useEffect, useCallback } from 'react'
import { ModalBody, ModalFooter } from 'reactstrap'
import { Button, Divider, IconHelpFilled, IconInfoFilled, IconTrashFilled, Input, NotificationBanner, Select } from 'takeat-design-system-ui-kit'
import { CustomTooltip, SelectArea, SelectsArea, TableArea, TableStyled, TableWrapper } from './styles'
import { ReactComponent as AddIcon } from '~/assets/img/add.svg'
import formatValue from '~/utils/formatValue'
import api from '~/services/api'
import toast from 'react-hot-toast'

export default function EditStock({ toggleModalEditStock, product, inputsAndIntermediaries, setInputsAndIntermediaries }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [inputsAndIntermediariesState, setInputsAndIntermediariesState] = useState(inputsAndIntermediaries)
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const [tooltip2Open, setTooltip2Open] = useState(false);
  const toggleIntermediaryTooltip = () => setTooltip2Open(!tooltip2Open);

  const [inputs, setInputs] = useState([]);
  const [intermediaries, setIntermediaries] = useState([])

  const [cmv, setCmv] = useState(0);

  const getInputs = useCallback(async () => {
    try {
      const response = await api.get('restaurants/inputs-minimal');
      const inputs_id =
        product.inputs.length > 0 ? product.inputs?.map((input) => input.input_id) : [];

      const new_inputs = response.data.filter(
        (input) => !inputs_id.includes(input.id)
      );
      const select_inputs = new_inputs.map((input) => ({
        value: input.id,
        label: input.name,
        unidade: input.unidade,
        unitary_price: input.unitary_price,
        quantidade: input.quantidade,
        name: input.name,
        id: input.id,
        input_id: input.id
      }));
      setInputs(select_inputs);
    } catch (err) {
      toast.error('Erro ao carregar insumos.');
      console.log(err.message);
    }
  }, [product]);

  useEffect(() => {
    const newCmv = product.current_cmv ? `${formatValue(product.current_cmv)}` : `${formatValue(inputsAndIntermediariesState.reduce((acc, item) => {
      const quantidade = parseFloat(item.quantidade) || 0;
      const unitaryPrice = parseFloat(item.unitary_price) || 0;
      return acc + quantidade * unitaryPrice;
    }, 0))}`

    setCmv(newCmv)
  }, [inputsAndIntermediariesState])

  const getIntermediaries = useCallback(async () => {
    try {
      const response = await api.get('restaurants/intermediary-minimal');
      const intermediaries_id =
        product.intermediaries.length > 0 ? product.intermediaries?.map((inter) => inter.intermediary_id) : [];

      const new_inter = response.data.filter(
        (inter) => !intermediaries_id.includes(inter.id)
      );
      const select_inter = new_inter.map((inter) => ({
        value: inter.id,
        label: inter.name,
        unidade: inter.unidade,
        unitary_price: inter.unitary_price,
        quantidade: inter.quantidade,
        name: inter.name,
        id: inter.id,
        intermediary_id: inter.id,
      }));
      setIntermediaries(select_inter);
    } catch (err) {
      toast.error('Erro ao carregar produtos intermediarios.');
    }
  }, [product]);

  const handleDelete = useCallback((item) => {
    try {
      setInputsAndIntermediariesState((old) => {
        const isInput = !!item.input_id;
        const filteredItems = old.filter((it) =>
          isInput
            ? it.input_id !== item.input_id
            : it.intermediary_id !== item.intermediary_id
        );

        return filteredItems;
      });
    } catch (error) {
      toast.error("Erro ao deletar do estoque");
      console.error(error);
    }
  }, []);

  const handleChangeValue = useCallback((item, quantidade) => {
    setInputsAndIntermediariesState(old => {
      return old.map(existingItem => {
        if (
          (item.input_id && existingItem.input_id === item.input_id) ||
          (item.intermediary_id && existingItem.intermediary_id === item.intermediary_id)
        ) {
          return { ...existingItem, quantidade };
        }
        return existingItem;
      });
    });
  }, []);

  useEffect(() => {
    getInputs();
  }, [getInputs]);

  useEffect(() => {
    getIntermediaries();
  }, [getIntermediaries]);

  const handleSubmit = useCallback(async () => {
    const inputsToSend = inputsAndIntermediariesState
      .map(it => ({
        ...it,
        quantidade: it.quantidade <= 0 ? "1" : it.quantidade
      }))
      .filter(it => it.input_id);

    const intermediariesToSend = inputsAndIntermediariesState
      .map(it => ({
        ...it,
        quantidade: it.quantidade <= 0 ? "1" : it.quantidade
      }))
      .filter(it => it.intermediary_id);


    try {
      api.put(`/restaurants/markup/update-inputs/${product.id}`, {
        inputs: inputsToSend,
        intermediaries: intermediariesToSend
      })
      setInputsAndIntermediaries(inputsAndIntermediariesState)
      toast.success("Estoque atualizado com sucesso")
      toggleModalEditStock()
    } catch (error) {
      toast.error("Erro ao salvar o estoque")
    }
  }, [inputsAndIntermediariesState])

  const [inputSelected, setInputSelected] = useState({})
  const [intermediarySelected, setIntermediarySelected] = useState({})

  function handleSelectInput(e) {
    setInputSelected(e)
  }

  function handleSelectIntermediary(e) {
    setIntermediarySelected(e)
  }

  function handleAssociateInput() {
    setInputsAndIntermediariesState(old => {
      const itemFind = old.find(item => item.input_id && item.id === inputSelected.id)

      if (itemFind) {
        toast.error("Esse item já está incluso.")
        return old
      }

      return [inputSelected, ...old]
    })
  }

  function handleAssociateIntermediary() {
    setInputsAndIntermediariesState(old => {
      const itemFind = old.find(item => !item.input_id && item.id === intermediarySelected.id)

      if (itemFind) {
        toast.error("Esse item já está incluso.")
        return old
      }

      return [intermediarySelected, ...old]
    })
  }

  //tooltips
  const [tooltipStates, setTooltipStates] = useState({});

  const toggleTooltip = (id) => {
    setTooltipStates((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };


  return (
    <>
      <div style={{ padding: 16, height: 56 }}>
        <h3 style={{ fontSize: 18, fontWeight: 600 }}>
          Receita / Ficha Técnica
        </h3>
      </div>

      <ModalBody style={{ padding: 16 }}>
        <NotificationBanner
          variant='warning'
          isOpen
        >
          <p>
            As edições feitas e salvas, serão replicadas para todo o sistema.
          </p>
        </NotificationBanner>
        <SelectsArea>
          <SelectArea>
            <Select
              options={inputs}
              onChange={(e) => handleSelectInput(e)}
              label={
                <span style={{ alignItems: 'center', display: 'flex', gap: 8 }}>
                  Insumo <IconHelpFilled fill="#545454" id="title-info" fontSize={18} />
                  <CustomTooltip
                    isOpen={tooltipOpen}
                    toggle={toggle}
                    target="title-info"
                    placement='bottom'
                  >
                    Matéria prima utilizada para preparação dos produtos.
                  </CustomTooltip>
                </span>}
              placeholder="Selecione o insumo"
            />
            <Button
              customColor='#2EC9B7'
              onClick={handleAssociateInput}
              icon={<AddIcon />}
              style={{
                width: 40,
                marginLeft: 8,
              }}
            />
          </SelectArea>
          <SelectArea>
            <Select
              options={intermediaries}
              onChange={(e) => handleSelectIntermediary(e)}
              label={
                <span style={{ alignItems: 'center', display: 'flex', gap: 8 }}>
                  Produto Intermediário <IconHelpFilled fill="#545454" id="title-info-2" fontSize={18} />
                  <CustomTooltip
                    isOpen={tooltip2Open}
                    toggle={toggleIntermediaryTooltip}
                    target="title-info-2"
                    placement='bottom'
                  >
                    Produto base que já passou por produção com adição de insumos. Ex: Massa de pizza e molhos artesanais.
                  </CustomTooltip>
                </span>}
              placeholder="Selecione o intermediário"
            />
            <Button
              customColor='#2EC9B7'
              onClick={handleAssociateIntermediary}
              icon={<AddIcon />}
              style={{
                width: 40,
                marginLeft: 8,
              }}
            />
          </SelectArea>
        </SelectsArea>

        <TableArea>
          <TableStyled>
            <table>
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Nome</th>
                  <th>
                    Consumo
                    <IconInfoFilled fill="#7a7a7a" id={`title-qtda-${product.id}`} style={{ marginLeft: 4, fontSize: 14 }} />
                    <CustomTooltip
                      isOpen={tooltipStates[`qtda-${product.id}`] || false}
                      toggle={() => toggleTooltip(`qtda-${product.id}`)}
                      target={`title-qtda-${product.id}`}
                      placement="bottom"
                    >
                      Quantidade e unidade de medida usada na ficha técnica.
                    </CustomTooltip>
                  </th>
                  <th>
                    Valor
                    <IconInfoFilled fill="#7a7a7a" id={`title-value2-${2}`} style={{ marginLeft: 4, fontSize: 14 }} />
                    <CustomTooltip
                      isOpen={tooltipStates[`value2-${2}`] || false}
                      toggle={() => toggleTooltip(`value2-${2}`)}
                      target={`title-value2-${2}`}
                      placement="bottom"
                    >
                      Valor de custo daquele insumo/produto intermediário na ficha técnica.
                    </CustomTooltip>
                  </th>
                  <th>
                    Estoque
                    <IconInfoFilled fill="#7a7a7a" id={`title-amount2-${3}`} style={{ marginLeft: 4, fontSize: 14 }} />
                    <CustomTooltip
                      isOpen={tooltipStates[`amount2-${3}`] || false}
                      toggle={() => toggleTooltip(`amount2-${3}`)}
                      target={`title-amount2-${3}`}
                      placement="bottom"
                    >
                      Quantidade daquele produto no estoque.
                    </CustomTooltip>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {inputsAndIntermediariesState && inputsAndIntermediariesState.length > 0 &&
                  inputsAndIntermediariesState.map(item => (
                    <tr key={item.input_id ? item.input_id : item.intermediary_id}>
                      <td>{item.input_id ? "Insumos" : "Intermediários"}</td>
                      <td>{item.name}</td>
                      <td>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                          }}
                        >
                          <Input
                            style={{
                              width: 100
                            }}
                            value={item.quantidade}
                            onChange={(e) => { handleChangeValue(item, e.target.value) }}
                          />
                        </div>
                      </td>
                      <td>
                        {formatValue(Number(item.quantidade > 0 ? item.quantidade : 1) * Number(item.unitary_price))}
                      </td>
                      <td>{`${item.available_amount || item.quantidade} ${item.unidade}`}</td>
                      <td>
                        <IconTrashFilled
                          fill="#c8131b"
                          fontSize={24}
                          onClick={() => { handleDelete(item) }}
                          style={{
                            cursor: "pointer"
                          }}
                        />
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </TableStyled>
        </TableArea>
      </ModalBody>
      <ModalFooter
        style={
          {
            justifyContent: "space-between",
            padding: 16,
          }
        }>
        <Divider
          style={{
            marginBottom: 16
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}>
          <span
            style={{
              fontWeight: 600,
              display: "flex",
              flexDirection: "column"
            }}>
            <span>Custo (CMV)</span>
            {product.is_cmv_manual && <span style={{ fontSize: 10, fontWeight: 400 }}>*CMV manual</span>}
          </span>
          <Button
            style={{
              color: "#222222",
              fontWeight: 400,
              marginLeft: 8
            }}
            customColor='#F6F6F6'
          >
            {cmv}
          </Button>
        </div>
        <div>
          <Button
            customColor='#C8131B'
            onClick={toggleModalEditStock}
          >
            Cancelar
          </Button>
          <Button
            customColor='#2EC9B7'
            style={{
              marginLeft: 8
            }}
            onClick={handleSubmit}
          >
            Alterar
          </Button>
        </div>
      </ModalFooter>
    </>
  )
}
